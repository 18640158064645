import React, { useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarker,
  faPhone,
  faEnvelope,
  faMapMarkerAlt,
  faPlus,
  faBars, // Add the bars icon for mobile toggle
} from "@fortawesome/free-solid-svg-icons";

function NavbarV2() {
  const [showDropdown1, setShowDropdown1] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [showDropdown3, setShowDropdown3] = useState(false);
  const [showDropdown4, setShowDropdown4] = useState(false);
  const [expanded, setExpanded] = useState(false); // Track the mobile menu state

  const handleDropdownEnter = (menuNumber) => {
    switch (menuNumber) {
      case 1:
        setShowDropdown1(true);
        break;
      case 2:
        setShowDropdown2(true);
        break;
      case 3:
        setShowDropdown3(true);
        break;
      case 4:
        setShowDropdown4(true);
        break;
      default:
        break;
    }
  };

  const handleDropdownLeave = (menuNumber) => {
    switch (menuNumber) {
      case 1:
        setShowDropdown1(false);
        break;
      case 2:
        setShowDropdown2(false);
        break;
      case 3:
        setShowDropdown3(false);
        break;
      case 4:
        setShowDropdown4(false);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="navbar-top">
        <div className="container">
          <div className="row">
          <div className="col-lg-4"> 
          <ul className="top_bar_social">
									<li><a href="https://www.facebook.com/solverwp/"><i className="fab fa-facebook-f" aria-hidden="true" /></a></li>
									<li><a href="https://www.twitter.com/solverwp/"><i className="fab fa-twitter" aria-hidden="true" /></a></li>
									<li><a href="https://www.instagram.com/solverwp/"><i className="fab fa-instagram" aria-hidden="true" /></a></li>
									<li><a href="https://www.skype.com/solverwp/"><i className="fab fa-skype" aria-hidden="true" /></a></li>
									<li><a href="https://www.pinterest.com/solverwp/"><i className="fab fa-pinterest-p" aria-hidden="true" /></a></li>
								</ul>     
          </div>
            <div className="col-lg-8 contactDetails">
              <ul>
                <li>
                  <p>
                    <FontAwesomeIcon icon={faMapMarkerAlt} /> 1209 Burlington
                    Tower Business Bay Dubai
                  </p>
                </li>
                <li>
                  <p>
                    <FontAwesomeIcon icon={faPhone} /> +0544977227
                  </p>
                </li>
                <li>
                  <p>
                    <FontAwesomeIcon icon={faEnvelope} /> contact@yahomes.ae
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Navbar expand="lg" className="custom-navbar-bg">
        <Container>
        <Navbar.Brand href="/">
            <div className="logo yaHomesLogo">
              <img
                src="/assets/img/logo.svg"
                alt="img"
              />
            </div>
          </Navbar.Brand>

          {/* Toggle menu icon for mobile */}
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(!expanded)}
          >
            <FontAwesomeIcon icon={faBars} style={{ color: "white" }} />
          </Navbar.Toggle>

          <Navbar.Collapse
            id="basic-navbar-nav"
            expanded={expanded ? "valueWhenTrue" : undefined}
          >            <Nav className="me-auto">
              <NavDropdown
                title="Buy"
                id="buy"
                className="submenuDropdown"
                show={showDropdown1}
                onMouseEnter={() => handleDropdownEnter(1)}
                onMouseLeave={() => handleDropdownLeave(1)}
              >
                <NavDropdown.Item href="/">
                  Apartment
                </NavDropdown.Item>
                <NavDropdown.Item href="/">
                  Townhouses
                </NavDropdown.Item>
                <NavDropdown.Item href="/">
                  Villas
                </NavDropdown.Item>
                <NavDropdown.Item href="/">
                  Penthouses
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                title="Rent"
                id="rent"
                className="submenuDropdown"
                show={showDropdown2}
                onMouseEnter={() => handleDropdownEnter(2)}
                onMouseLeave={() => handleDropdownLeave(2)}
              >
                <NavDropdown.Item href="/">
                  Apartment
                </NavDropdown.Item>
                <NavDropdown.Item href="/">Villas</NavDropdown.Item>
                <NavDropdown.Item href="/">
                  Townhouses
                </NavDropdown.Item>
                <NavDropdown.Item href="/">
                  Penthouses
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                title="OffPlan"
                id="offplan"
                className="submenuDropdown"
                show={showDropdown3}
                onMouseEnter={() => handleDropdownEnter(3)}
                onMouseLeave={() => handleDropdownLeave(3)}
              >
                <NavDropdown.Item href="/">
                  Apartment
                </NavDropdown.Item>
                <NavDropdown.Item href="/">
                  Villas
                </NavDropdown.Item>
                <NavDropdown.Item href="/">
                  Townhouses
                </NavDropdown.Item>
                <NavDropdown.Item href="/">
                  Penthouses
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link href="/offplan-projects-in-dubai">
                Dubai Projects
              </Nav.Link>
              <Nav.Link href="/blogs">Explore Dubai</Nav.Link>

              <NavDropdown
                title="Our Mission"
                id="ourmission"
                className="submenuDropdown"
                show={showDropdown4}
                onMouseEnter={() => handleDropdownEnter(4)}
                onMouseLeave={() => handleDropdownLeave(4)}
              >
                <NavDropdown.Item href="/about-us">About Us</NavDropdown.Item>
                <NavDropdown.Item href="/list-with-us">
                  Sell With Us
                </NavDropdown.Item>
                <NavDropdown.Item href="/contact">Contact Us</NavDropdown.Item>
              </NavDropdown>

              {/* Yellow Button with Plus Icon */}
              <button className="yellow-button">
                <FontAwesomeIcon icon={faPlus} /> Submit
              </button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarV2;
