import React, { useState } from "react";
import { Tabs, Tab, Container, Row, Col } from "react-bootstrap";
import MarvelImages from "./MarvelImages";
// Import your second component

const ArchitectureMarvel = () => {
  const [key, setKey] = useState("tab1"); // Initial tab key

  return (
    <Container className="mt-100">
    <center>
      <h2>An Architectural Marvel</h2>
    </center>
    
    <Row className="justify-content-center">
      <Col xs={12} md={8} className="text-center">
        <Tabs
          id="controlled-tabs"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3 custom-tabs" // Add a custom class for styling
        >
          <Tab eventKey="tab1" title="Exteriors">
            <MarvelImages /> {/* Render your first component */}
          </Tab>
          <Tab eventKey="tab2" title="Interiors">
            <MarvelImages /> {/* Render your first component */}
          </Tab>
        </Tabs>
      </Col>
    </Row>
  </Container>
  );
};

export default ArchitectureMarvel;
