import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Row,
} from "react-bootstrap";
import { TEAM_API_URL } from "../../constants/config";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReCAPTCHA from "react-google-recaptcha";

const TeamMemberDetails = ({ details }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [captchaValue, setCaptchaValue] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Clear the error message when the user starts typing
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform form validation
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Perform CAPTCHA validation
    if (!captchaValue) {
      setErrors({
        captcha: "Please complete the CAPTCHA.",
      });
      return;
    }

    // Submit the form data
    // You can send the form data to your server here

    // Clear the form
    setFormData({
      name: "",
      email: "",
      phone: "",
      message: "",
    });
  };

  const validateForm = (data) => {
    const errors = {};

    if (!data.name) {
      errors.name = "Name is required.";
    }

    if (!data.email) {
      errors.email = "Email is required.";
    } else if (!isValidEmail(data.email)) {
      errors.email = "Invalid email address.";
    }

    if (!data.phone) {
      errors.phone = "Phone number is required.";
    }

    if (!data.message) {
      errors.message = "Message is required.";
    }

    return errors;
  };

  const isValidEmail = (email) => {
    // Simple email validation regex
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };
  return (
    <Container>
      <Row className="custom-row-margin">
        <Col lg={6} sm={12}>
          <LazyLoadImage
            effect="blur"
            alt={details?.name}
            src={`${TEAM_API_URL}/${details?.path}`}
          />

          <h2>{details.name}</h2>
          <p
            className={`smooth-scroll ${
              showFullDescription ? "" : "description-summary"
            }`}
          >
            {showFullDescription
              ? details?.description
              : details?.description?.substring(0, 200)}
          </p>

          {details?.description?.length > 200 && (
            <Button
              variant="link"
              onClick={toggleDescription}
              className="read-more-button"
            >
              {showFullDescription ? "Read Less" : "Read More"}
            </Button>
          )}
        </Col>
        <Col lg={6} sm={12}>
          <Card className="contact-card">
            <Card.Body>
              <h4 className="contact-heading">Contact for Enquiry</h4>
              <Form onSubmit={handleSubmit} className="contact-form">
        <Form.Group controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            placeholder="Enter your name"
            value={formData.name}
            onChange={handleChange}
          />
          {errors.name && <span className="error">{errors.name}</span>}
        </Form.Group>

        <Form.Group controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            placeholder="Enter your email"
            value={formData.email}
            onChange={handleChange}
          />
          {errors.email && <span className="error">{errors.email}</span>}
        </Form.Group>

        <Form.Group controlId="phone">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            type="tel"
            name="phone"
            placeholder="Enter your phone number"
            value={formData.phone}
            onChange={handleChange}
          />
          {errors.phone && <span className="error">{errors.phone}</span>}
        </Form.Group>

        <Form.Group controlId="message">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            name="message"
            placeholder="Enter your message"
            value={formData.message}
            onChange={handleChange}
          />
          {errors.message && <span className="error">{errors.message}</span>}
        </Form.Group>

        <div className="col-12 mb-4">
          <ReCAPTCHA
                    sitekey="6LcM6ycoAAAAAHto-ZQ3LASBGX866X1Ccgxt-fDq"
                    onChange={(value) => setCaptchaValue(value)}
          />
          {errors.captcha && <span className="error">{errors.captcha}</span>}
        </div>

        <Button className="submitButton" variant="primary" type="submit">
          Submit
        </Button>
      </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default TeamMemberDetails;
