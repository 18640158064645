import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { ABOUT_IMAGE_URL } from "../../../constants/config";

const Upfront = ({ offPlanDetails }) => {
  return (
    <Container className="mt-100">
      <Row>
        {offPlanDetails?.map((offplan) => (
          <>
            <Col xs={12} md={6}>
              <Image
                src={`${ABOUT_IMAGE_URL}/${offplan?.sec3_img}`}
                alt="Image"
                fluid
                className="image-left"
                loading="lazy"
              />
            </Col>

            <Col xs={12} md={6}>
              <div className="text-right">
                <h2>{offplan?.sec2_h1}</h2>
                <p>{offplan?.sec2_desc}</p>
                <Button variant="warning">Request Available Units and Prices</Button>

              </div>
            </Col>
          </>
        ))}
      </Row>
    </Container>
  );
};

export default Upfront;
