import React from 'react';
import { Container, Breadcrumb } from 'react-bootstrap';
import { capitalizeWords } from '../../utils';
import { useParams } from 'react-router-dom';

const IntroSection = ({separatedStrings}) => {
  const {property_for}=useParams()

  return (
    <Container className='mt-120'>
					<h3>{ "Properties" } For {capitalizeWords(separatedStrings[0]) } In {capitalizeWords(separatedStrings[1])}</h3>
    <Breadcrumb className='custom-breadcrumb'>
      <Breadcrumb.Item href="#">Dubai</Breadcrumb.Item>
      <Breadcrumb.Item href="#" active>
        Properties
      </Breadcrumb.Item>
    </Breadcrumb>
  </Container>

  );
};

export default IntroSection;
