export const API_URL="https://yahomes.ae/API/api"

export const TEAM_API_URL="http://yahomes.ae/admin/uploads"

export const ABOUT_IMAGE_URL="http://yahomes.ae/admin/uploads"

export const PROPERTY_IMAGES_URL="http://yahomes.ae/admin/uploads/property_img"


export const ADMIN_PROPERTY_IMAGES_URL ="http://yahomes.ae/admin/uploads/cat"


const storyItems = [
    {
        year: '2005',
        event: 'Founding of Our Company',
        imageSrc: 'https://entrepreneurhandbook.co.uk/wp-content/uploads/2016/03/Selecting-a-co-founder.png.webp',
        altText: 'Founding of Our Company',
    },
    {
        year: '2010',
        event: 'Expansion to New Markets',
        imageSrc: 'https://blog.stepes.com/wp-content/uploads/2016/02/shutterstock_189033716-1.jpg',
        altText: 'Expansion to New Markets',
    },
    {
        year: '2015',
        event: 'Launch of Innovative Product',
        imageSrc: 'https://cloudinary.hbs.edu/hbsit/image/upload/s--F71MmImz--/f_auto,c_fill,h_375,w_750,/v20200101/F6BE5CED2BCD21FC0343B7D9A5B09266.jpg',
        altText: 'Launch of Innovative Product',
    },
    {
        year: '2020',
        event: 'Celebrating a Decade of Success',
        imageSrc: 'https://www.sqprofessional.com/img/blog/celebrating-a-decade-of-success-3.jpg',
        altText: 'Celebrating a Decade of Success',
    },
];

export default storyItems;