import React, { useState } from 'react';
import { Container, Row, Col, Dropdown, Button } from 'react-bootstrap';

const SearchBar = () => {
  // Dummy data for dropdown options
  const communityOptions = ['Community 1', 'Community 2', 'Community 3'];
  const subcommunityOptions = ['Subcommunity 1', 'Subcommunity 2', 'Subcommunity 3'];
  const propertyTypeOptions = ['Property Type 1', 'Property Type 2', 'Property Type 3'];
  const priceOptions = ['Price 1', 'Price 2', 'Price 3'];
  const bedroomsOptions = ['1 Bedroom', '2 Bedrooms', '3 Bedrooms'];

  const [selectedCommunity, setSelectedCommunity] = useState('');
  const [selectedSubcommunity, setSelectedSubcommunity] = useState('');
  const [selectedPropertyType, setSelectedPropertyType] = useState('');
  const [selectedPrice, setSelectedPrice] = useState('');
  const [selectedBedrooms, setSelectedBedrooms] = useState('');

  return (
    <Container className='mt-4'>
      <div className='search-bar-container'>
        <Row className='justify-content-center align-items-center'>
          <Col md={2} sm={6} xs={12} className='mb-2'>
            <Dropdown>
              <Dropdown.Toggle variant='light' id='community-dropdown'>
                {selectedCommunity || 'Community'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {communityOptions.map((option, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => setSelectedCommunity(option)}
                  >
                    {option}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col md={2} sm={6} xs={12} className='mb-2'>
            <Dropdown>
              <Dropdown.Toggle variant='light' id='subcommunity-dropdown'>
                {selectedSubcommunity || 'Subcommunity'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {subcommunityOptions.map((option, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => setSelectedSubcommunity(option)}
                  >
                    {option}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col md={2} sm={6} xs={12} className='mb-2'>
            <Dropdown>
              <Dropdown.Toggle variant='light' id='property-type-dropdown'>
                {selectedPropertyType || 'Property Type'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {propertyTypeOptions.map((option, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => setSelectedPropertyType(option)}
                  >
                    {option}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col md={2} sm={6} xs={12} className='mb-2'>
            <Dropdown>
              <Dropdown.Toggle variant='light' id='price-dropdown'>
                {selectedPrice || 'Price'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {priceOptions.map((option, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => setSelectedPrice(option)}
                  >
                    {option}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col md={2} sm={6} xs={12} className='mb-2'>
            <Dropdown>
              <Dropdown.Toggle variant='light' id='bedrooms-dropdown'>
                {selectedBedrooms || 'Bedrooms'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {bedroomsOptions.map((option, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => setSelectedBedrooms(option)}
                  >
                    {option}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col md={2} sm={12} xs={12} className='mb-2'>
            <Button variant='primary' className='search-button'>
              Search
            </Button>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default SearchBar;
