export const CATEGORY_PROPERTIES_REQUEST ="CATEGORY_PROPERTIES_REQUEST"
export const CATEGORY_PROPERTIES_SUCCESS ="CATEGORY_PROPERTIES_SUCCESS"
export const CATEGORY_PROPERTIES_FAILURE ="CATEGORY_PROPERTIES_FAILURE"



export const CATEGORY_PROPERTIES_REQUEST_RENT ="CATEGORY_PROPERTIES_REQUEST_RENT"
export const CATEGORY_PROPERTIES_SUCCESS_RENT ="CATEGORY_PROPERTIES_SUCCESS_RENT"
export const CATEGORY_PROPERTIES_FAILURE_RENT ="CATEGORY_PROPERTIES_FAILURE_RENT"

export const CATEGORY_PROPERTIES_TOWNHOUSE_REQUEST ="CATEGORY_PROPERTIES_TOWNHOUSE_REQUEST"
export const CATEGORY_PROPERTIES_TOWNHOUSE_SUCCESS ="CATEGORY_PROPERTIES_TOWNHOUSE_SUCCESS"
export const CATEGORY_PROPERTIES_TOWNHOUSE_FAILURE ="CATEGORY_PROPERTIES_TOWNHOUSE_FAILURE"


export const CATEGORY_PROPERTIES_TOWNHOUSE_REQUEST_RENT ="CATEGORY_PROPERTIES_TOWNHOUSE_REQUEST_RENT"
export const CATEGORY_PROPERTIES_TOWNHOUSE_SUCCESS_RENT ="CATEGORY_PROPERTIES_TOWNHOUSE_SUCCESS_RENT"
export const CATEGORY_PROPERTIES_TOWNHOUSE_FAILURE_RENT ="CATEGORY_PROPERTIES_TOWNHOUSE_FAILURE_RENT"





export const CATEGORY_PROPERTIES_APARTMENT_REQUEST ="CATEGORY_PROPERTIES_APARTMENT_REQUEST"
export const CATEGORY_PROPERTIES_APARTMENT_SUCCESS ="CATEGORY_PROPERTIES_APARTMENT_SUCCESS"
export const CATEGORY_PROPERTIES_APARTMENT_FAILURE ="CATEGORY_PROPERTIES_APARTMENT_FAILURE"


export const CATEGORY_PROPERTIES_APARTMENT_REQUEST_RENT ="CATEGORY_PROPERTIES_APARTMENT_REQUEST_RENT"
export const CATEGORY_PROPERTIES_APARTMENT_SUCCESS_RENT ="CATEGORY_PROPERTIES_APARTMENT_SUCCESS_RENT"
export const CATEGORY_PROPERTIES_APARTMENT_FAILURE_RENT ="CATEGORY_PROPERTIES_APARTMENT_FAILURE_RENT"


export const CATEGORY_PROPERTIES_PENTHOUSE_REQUEST ="CATEGORY_PROPERTIES_PENTHOUSE_REQUEST"
export const CATEGORY_PROPERTIES_PENTHOUSE_SUCCESS ="CATEGORY_PROPERTIES_PENTHOUSE_SUCCESS"
export const CATEGORY_PROPERTIES_PENTHOUSE_FAILURE ="CATEGORY_PROPERTIES_PENTHOUSE_FAILURE"



export const CATEGORY_PROPERTIES_PENTHOUSE_REQUEST_RENT ="CATEGORY_PROPERTIES_PENTHOUSE_REQUEST_RENT"
export const CATEGORY_PROPERTIES_PENTHOUSE_SUCCESS_RENT ="CATEGORY_PROPERTIES_PENTHOUSE_SUCCESS_RENT"
export const CATEGORY_PROPERTIES_PENTHOUSE_FAILURE_RENT ="CATEGORY_PROPERTIES_PENTHOUSE_FAILURE_RENT"




export const CATEGORY_FOR_SALE_OR_RENT_REQUEST ="CATEGORY_FOR_SALE_OR_RENT_REQUEST"
export const CATEGORY_FOR_SALE_OR_RENT_SUCCESS ="CATEGORY_FOR_SALE_OR_RENT_SUCCESS"
export const CATEGORY_FOR_SALE_OR_RENT_FAIL ="CATEGORY_FOR_SALE_OR_RENT_FAIL"