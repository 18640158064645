import axios from "axios";
import { GET_OFFPLAN_CATEGORY_FAILURE, GET_OFFPLAN_CATEGORY_REQUEST, GET_OFFPLAN_CATEGORY_SUCCESS, GET_OFFPLAN_DETAIL_FAILURE, GET_OFFPLAN_DETAIL_REQUEST, GET_OFFPLAN_DETAIL_SUCCESS, GET_OFF_PLAN_FAIL, GET_OFF_PLAN_REQUEST, GET_OFF_PLAN_SUCCESS } from "../constants/offPlanConstants"
import { GET_PROPERTIES_FOR__FAILURE } from "../constants/propertyConstants";
import { API_URL } from "../constants/config";

export const getOffPlanApi = (heading1) => async (dispatch) => {
    try {
      dispatch({ type: GET_OFF_PLAN_REQUEST });
  
      const  {data}  = await axios.get(`${API_URL}/offplan_page/${heading1}`);

  
      dispatch({
        type: GET_OFF_PLAN_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_OFF_PLAN_FAIL,
      });
    }
  };




  export const getOffPlanCategoryApi = () => async (dispatch) => {
    try {
      dispatch({ type:GET_OFFPLAN_CATEGORY_REQUEST });
  
      const  {data}  = await axios.get(`${API_URL}/offplans`);

  
      dispatch({
        type: GET_OFFPLAN_CATEGORY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type:GET_OFFPLAN_CATEGORY_FAILURE,
      });
    }
  };



  export const OffPlanDetailApi = (heading1) => async (dispatch) => {
    
    try {
      dispatch({ type: GET_OFFPLAN_DETAIL_REQUEST });
      
      const  {data}  = await axios.get(`${API_URL}/offplan_page/${heading1}`);
    
      dispatch({
        type: GET_OFFPLAN_DETAIL_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_OFFPLAN_DETAIL_FAILURE,
      });
    }
  };



  