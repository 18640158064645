import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const OurApproach = () => {
    return (
        <div className="our-approach-section py-5">
            <Container>
                <Row className="align-items-center">
                    <Col md={6}>
                    <LazyLoadImage
        src="https://dx5eum835vc57.cloudfront.net/trilogyfunding.com.au/wp-content/uploads/2014/12/31233347/behavioural-bias-02.12.20141.jpg"
        alt="Our Approach"
        className="img-fluid"
      />
                    </Col>
                    <Col md={6}>
                        <div className="approach-text">
                            <h2>Our Approach</h2>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
                                volutpat tristique turpis, in euismod ex faucibus eu. Curabitur
                                quis vehicula tellus, eget interdum quam. Nullam eleifend
                                faucibus urna, in fringilla orci hendrerit vitae. Aenean
                                facilisis lectus vel ultricies.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default OurApproach;
