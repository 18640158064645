import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const OurMission = () => {
  return (
    <section className="our-mission mt-60">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="image-container">
            <LazyLoadImage
        src="https://99plot.com/assets/images/mision.jpg"
        alt="Our Mission"
        eefect="opacity"
        className="img-fluid hover-zoom"
      />
            </div>
          </div>
          <div className="col-md-6">
            <div className="content-container">
              <h2>Our Mission</h2>
              <p>
                At Ya Homes, our mission is to empower individuals and
                businesses to thrive in a digital world. We are dedicated to
                providing innovative solutions that make a positive impact on
                our customers' lives. Through a relentless pursuit of
                excellence, creativity, and customer-centricity, we strive to
                achieve our vision of transforming the future.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurMission;
