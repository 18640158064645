import React, { useState } from "react";
import { Button, Col, Container, Row, Image,Modal,Form } from "react-bootstrap";
import { ABOUT_IMAGE_URL } from "../../../constants/config";

const AboutOffPlan = ({ offPlanDetails }) => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Clear the error message when the user starts typing in a field
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform form validation
    let valid = true;
    const newErrors = {};

    if (formData.name.trim() === "") {
      newErrors.name = "Name is required.";
      valid = false;
    }

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(formData.email)) {
      newErrors.email = "Invalid email address.";
      valid = false;
    }

    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(formData.phone)) {
      newErrors.phone = "Invalid phone number (10 digits only).";
      valid = false;
    }

    if (!valid) {
      setFormErrors(newErrors);
      return;
    }

    // If form is valid, close the modal and display a success message
    setShowModal(false);

    // You can perform further actions like submitting the form data to an API here
    // Example: sendDataToServer(formData);

    // Clear the form fields
    setFormData({
      name: "",
      email: "",
      phone: "",
    });

    // Display a success message (you can customize this part)
    alert("Thanks for getting in touch with us!");
  };

  const handleModalShow = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    // Clear form errors when the modal is closed
    setFormErrors({
      name: "",
      email: "",
      phone: "",
    });
  };

  return (
    <>
     <Container className="mt-120">
      <Row>
        {offPlanDetails?.map((item, index) => (
          <React.Fragment key={item?.id}>
            <Col md={6}>
              <div className="text-container">
                <h4>{item?.heading1}</h4>
                <p>{item?.description1}</p>
                <div className="button-container">
                  <Button
                    variant="primary"
                    className="custom-button primary"
                    onClick={handleModalShow}
                  >
                    Discover More
                  </Button>{" "}
                  <Button
                    variant="secondary"
                    className="custom-button secondary"
                    onClick={handleModalShow}
                  >
                    Get Project Brochure
                  </Button>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <Image
                src={`${ABOUT_IMAGE_URL}/${item?.sec2_img}`}
                alt="Image"
                fluid
                loading="lazy"
              />
            </Col>
          </React.Fragment>
        ))}
      </Row>

      {/* Modal */}
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Contact Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                isInvalid={formErrors.name !== ""}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                isInvalid={formErrors.email !== ""}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.email}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formPhone">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="tel"
                placeholder="Enter your phone number"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                isInvalid={formErrors.phone !== ""}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.phone}
              </Form.Control.Feedback>
            </Form.Group>

            <Button clas variant="dark" type="submit">
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Row className="mt-100">
        {
          offPlanDetails?.map((item,index)=>(
           <>
             <Col xs={12} md={4} lg={4}>
        <div className="icon-container">
          <i className="fas fa-money-bill-alt fa-3x"></i>
        </div>
        <h3>Starting Price {" "}AED {item?.starting_price}</h3>
      </Col>
             <Col xs={12} md={4}>
        <div className="icon-container">
          <i className="fas fa-credit-card fa-3x"></i>
        </div>
        <h3>{item?.payment_plan}</h3>
      </Col>
      <Col xs={12} md={4}>
        <div className="icon-container">
          <i className="fas fa-hand-holding-usd fa-3x"></i>
        </div>
        <h3>{item?.handover} hNDOVER</h3>
      </Col>
    
           </>

          ))
        }
     
   
    </Row>
    </Container>
   
    </>
   
  );
};


export default AboutOffPlan;
