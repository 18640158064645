import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import HomeV1 from "./components/home-v1";
import HomeV2 from "./components/home-v2";
import HomeV3 from "./components/home-v3";
import ProptertyDetails from "./components/property-details";
import ProptertyGrid from "./components/property-grid";
import Propterty from "./components/property";
import About from "./components/about";
import Team from "./components/team";
import SignIn from "./components/sign-in";
import SignUp from "./components/sign-up";
import AddProperty from "./components/add-property";
import Contact from "./components/contact";
import Blog from "./components/blog";
import BlogDetails from "./components/blog-details";
import TeamMember from "./components/TeamMember";
import AboutPage from "./components/about";
import Career from "./components/Career";
import ExclusiveProperties from "./components/ExclusiveProperties/ExclusiveProperties";
import ExclusivePropertiesDetails from "./components/ExclusiveProperties/ExclusivePropertiesDetails";
import PropertyDetails from "./components/global-components/Property/PropertyDetails";
import CommunityPage from "./components/global-components/Property/CommunityPage";
import SubCommunityPage from "./components/global-components/Property/SubCommunity/SubCommunityPage";
import TypePropertyPage from "./components/global-components/Property/Type/TypePropertyPage";
import ExclusiveCommunityPage from "./components/ExclusiveProperties/ExclusiveCommunityPage";
import ExclusivePropertiesSubCommunity from "./components/ExclusiveProperties/ExclusivePropertiesSubCommunity";
import OffPlanProjects from "./components/global-components/OffPlanProjects";
import OffPlan from "./components/global-components/PffPlan/OffPlan";
import SellWithUs from "./components/global-components/SellWithUs";
import ExploreDubai from "./components/global-components/ExploreDubai";

const App = () => {
  return (
    <BrowserRouter>
        <Switch>
          <Route exact path="/" component={HomeV1} />
          <Route exact path="/home-v2" component={HomeV2} />
          <Route exact path="/home-v3" component={HomeV3} />
          <Route exact path="/property-details" component={ProptertyDetails} />
          <Route exact path="/property-grid" component={ProptertyGrid} />
          <Route exact path="/property" component={Propterty} />
          <Route exact path="/about" component={About} />
          <Route exact path="/team" component={Team} />
          <Route exact path="/team/:name/:id" component={TeamMember} />
          <Route exact path="/about-us" component={AboutPage} />

          <Route exact path="/career" component={Career} />
          <Route
            exact
            path="/properties-for:property_for:property_city"
            component={ExclusiveProperties}
          />
          <Route
            exact
            path="/properties/:community/:subcomm/th:id"
            component={PropertyDetails}
          />

          <Route
            exact
            path="/properties/:community/:subcomm/properties-for-:property_for-:property_level/th:id"
            component={PropertyDetails}
          />
          <Route exact path="/sign-in" component={SignIn} />
          <Route exact path="/sign-up" component={SignUp} />
          <Route exact path="/add-property" component={AddProperty} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/blog-details" component={BlogDetails} />
          <Route
            exact
            path="/properties/properties-for-:property_for-:property_community"
            component={ExclusiveCommunityPage}
          />
          <Route
            exact
            path="/properties/:property_community/properties-for-:property_for-:property_subcomm"
            component={ExclusivePropertiesSubCommunity}
          />

          <Route
            exact
            path="/:property_city/:property_type-for-:property_for-:property_community"
            component={CommunityPage}
          />
          <Route
            exact
            path="/:property_city/:property_community/:property_type-for-:property_for-:property_sub_community"
            component={SubCommunityPage}
          />

          <Route
            exact
            path="/:property_type-for-:property_for-:property_city"
            component={TypePropertyPage}
          />

          <Route
            exact
            path="/:property_city/:property_community/:property_type-for-:property_for-:property_sub_community/th:id"
            component={PropertyDetails}
          />
          <Route
            exact
            path="/:property_city/:property_community/:property_sub_community/:property_type-for-:property_for-:property_level/th:id"
            component={PropertyDetails}
          />

          <Route
            exact
            path="/offplan-projects-in-dubai"
            component={OffPlanProjects}
          />

          <Route
            exact
            path="/offplan-projects-in-dubai/:heading1"
            component={OffPlan}
          />
          <Route exact path="/list-with-us" component={SellWithUs} />

          <Route exact path="/blogs" component={ExploreDubai} />

          <Route exact path="/blog/:blog_heading/:currentBlogId" component={BlogDetails} />

        </Switch>
    </BrowserRouter>
  );
};

export default App;
