import React from "react";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import {
  capitalizeWords,
  replaceHyphensWithSpaces,
  url,
} from "../../../../utils";

const SubCommunityHeader = (props) => {
  return (
    <section className="my-section mt-120">
      <Container>
        <Row>
          <Col>
            <h2>{replaceHyphensWithSpaces( props?.property_type) } For { props?.property_for } In {props?.property_city} </h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item href="/">
                {replaceHyphensWithSpaces(props?.property_city)}
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {replaceHyphensWithSpaces(props?.property_type)}{" "}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SubCommunityHeader;
