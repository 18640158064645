import React, { useEffect } from "react";
import ImageCarousel from "../../section-components/ImageCarousel";
import NavbarV2 from "../navbar-v2";
import PropertyHeaderDetails from "./PropertyHeaderDetails";
import {
  Link,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { getSingalPropertyDetailsApi } from "../../../actions/propertiesActions";
import { Col, Container, Row } from "react-bootstrap";
import PropertyHeaderDetailsWithoutLevel from "./PropertyHeaderDetailsWithoutLevel";
import PropertyFeatures from "./PropertyFeatures";
import RelatedProperties from "./RelatedProperties";
import PropertyDescription from "./PropertyDescription";
import PropertyAmenities from "./PropertyAminities";
import NearByPlaces from "./NearByPlaces";
import GoogleMap from "../../section-components/GoogleMap";
import MetaData from "../../../layout/MetaData";
import Footer_v2 from "../footer-v2";
import Navbar from '../../global-components/navbar-v2'

const PropertyDetails = () => {
  const location = useLocation();
  const { pathname } = location;
  const isExclusiveProperty = pathname.includes("properties");
  let publicUrl = process.env.PUBLIC_URL + "/";

  const { loading: ld, propertyDetails } = useSelector(
    (state) => state.propertyDetails
  );
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    dispatch(getSingalPropertyDetailsApi(id));
  }, [dispatch]);

  return (
    <>
      <MetaData
        title={propertyDetails?.property_seo_title}
        metaDesription={propertyDetails?.property_seo_dsc}
      />
      <Navbar />

      {/* ImageCarousel wrapped in a col-lg-12 */}
      <ImageCarousel
        details={propertyDetails}
        name={propertyDetails?.property_name}
      />
      {propertyDetails?.level !== "" ? (
        <PropertyHeaderDetails
          isExclusiveProperty={isExclusiveProperty}
          details={propertyDetails}
        />
      ) : (
        <PropertyHeaderDetailsWithoutLevel
          isExclusiveProperty={isExclusiveProperty}
          details={propertyDetails}
        />
      )}
      {propertyDetails !== null && (
        <PropertyDescription
          details={propertyDetails}
          description={propertyDetails?.property_content}
        />
      )}

      <RelatedProperties details={propertyDetails} />

      <GoogleMap
        google={"google"}
        latitude={propertyDetails?.property_location_lat}
        longitude={propertyDetails?.property_location_long}
      />

      <Footer_v2 map={true} />
    </>
  );
};

export default PropertyDetails;
