import React from "react";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import { capitalizeWords, url } from "../../../../utils";

const SubCommunityHeader = (props) => {
  return (
    <section className="my-section mt-120">
      <Container>
        <Row>
          <Col>
            <h2>
              {props?.type} For {props?.for} In {capitalizeWords(props?.subComm)}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item href="/">
                {capitalizeWords(props?.city)}
              </Breadcrumb.Item>
              <Breadcrumb.Item
                href={`/${url(props?.type)}-for-${url(props?.for)}-${url(
                    props?.city
                  )}`}
              >
                {props?.type}
              </Breadcrumb.Item>
              <Breadcrumb.Item href={`/${url(props?.city)}/${url(props?.type)}-for-${url(
                        props?.for
                      )}-${url(props?.comm)}`} >
                {capitalizeWords(props?.comm)}
              </Breadcrumb.Item>
                 <Breadcrumb.Item >
                {capitalizeWords(props?.subComm)}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SubCommunityHeader;
