import React, { useEffect } from "react";
import Navbar from "./global-components/navbar-v2";
import PageHeader from "./global-components/page-header";
import About from "./section-components/about";
import Counter from "./section-components/counter";
import Service from "./section-components/service-v3";
import AgentV3 from "./section-components/agent-v3";
import Testimonial from "./section-components/testimonial-v3";
import MoreInfo from "./section-components/more-info";
import Footer from "./global-components/footer-v2";
import { useDispatch, useSelector } from "react-redux";
import { getAboutUsApi } from "../actions/aboutUsActions";
import { getHomePageApi } from "../actions/homePageActions";
import MetaData from "../layout/MetaData";
import OurStory from "./section-components/OurStory";
import storyItems from "../constants/config";
import QuickGlance from "./section-components/QuickGlance";
import OurApproach from "./section-components/OurApproach";
import Team from "./section-components/team";
import { getCatgoryPropertiesRentApi } from "../actions/catgoryActions";
import { getContactUsApi } from "../actions/contactActions";
import { getCarrerApi } from "../actions/careerActions";
import WorkWithUs from "./section-components/WorkWithUs";

const Career = () => {
  const { loading, career } = useSelector((state) => state.career);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCarrerApi());
  }, [dispatch]);

  return (
    <div>
      <MetaData
        title={career[0]?.seo_titel}
        metaKeyword={career[0]?.seo_teg}
        metaDesription={career[0]?.seo_des}
      />

      <Navbar />
      <PageHeader headertitle="Career" />
      <WorkWithUs career={career}/>

      <Testimonial />
      <MoreInfo />
      <Footer />
    </div>
  );
};

export default Career;
