import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getRelatedProperties } from '../../../actions/propertiesActions'
import { getPropertiesTypeUtils, replaceHyphensWithSpaces } from '../../../utils'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import PropertyGrid from '../../PropertyGrid'

const RelatedProperties = ({details}) => {
    const{property_type,property_for,property_city}=useParams()
    const [loading, setLoading] = useState(false)
    const [relatedProperties, setRelatedProperties] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await getPropertiesTypeUtils(replaceHyphensWithSpaces(property_type), property_for, property_city);
                setRelatedProperties(data)
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };
    
        fetchData();
    }, []);
  return (
    <PropertyGrid related={true} categoryProperties={relatedProperties} />
  )
}

export default RelatedProperties