import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const QuickGlance = ({ aboutUsContent }) => {
  const cardItems = [
    {
      imageSrc:
        "https://assets-news.housing.com/news/wp-content/uploads/2020/12/08160745/What-is-a-real-estate-brokerage-FB-1200x700-compressed.jpg",
      title: "Real Estate Brokerage",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus dapibus felis sed lacus ultricies.",
    },
    {
      imageSrc:
        "https://assets-news.housing.com/news/wp-content/uploads/2021/11/25203239/Indian-property-market-shutterstock_1700575657-1200x700-compressed.jpg",
      title: "Real Estate Investment",
      description:
        "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.",
    },
    {
      imageSrc:
        "https://assetmonk.com/wp-content/uploads/Features-of-Profitable-CRE-jpg.webp",
      title: "Property Development",
      description:
        "Nulla facilisi. Sed convallis tincidunt justo, non iaculis urna iaculis et. Nam ac ex et turpis placerat interdum.",
    },
  ];

  return (
    <div className="card-section mt-100">
      <Container>
        <Row>
          <Col>
            <h2>A QUICK GLANCE AT OUR SERVICES</h2>
            <p>
              Our aim is to serve our clients by providing them with the best
              real estate services they can get, which includes, among others:
            </p>
          </Col>
        </Row>
        <Row className="">
          {cardItems.map((item, index) => (
            <Col lg={4} md={4} key={index}>
              <Card>
                <Card.Img
                  variant="top"
                  src={item.imageSrc}
                  alt={item.title}
                  loading="lazy"
                  className="serviceImages"
                />
                <Card.Body>
                  <Card.Title>{item.title}</Card.Title>
                  <Card.Text>{item.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default QuickGlance;
