import axios from "axios";
import {GET_ALL_COMMUNITY_FAILURE, GET_ALL_COMMUNITY_REQUEST, GET_ALL_COMMUNITY_SUCCESS, GET_ALL_SUB_IN_COMMUNITY_FAILURE, GET_ALL_SUB_IN_COMMUNITY_REQUEST, GET_ALL_SUB_IN_COMMUNITY_SUCCESS, GET_ALL_TYPE_FAILURE, GET_ALL_TYPE_REQUEST, GET_ALL_TYPE_SUCCESS, GET_COMMUNITY_CATEGORY__REQUEST, GET_COMMUNITY_CATEGORY__SUCCESS, GET_COMMUNITY_CATEGORY_FAILURE, GET_COMMUNITY_GUIDELINES__REQUEST,GET_COMMUNITY_GUIDELINES__SUCCESS, GET_COMMUNITY_GUIDELINES_FAILURE } from "../constants/communityConstants";
import { GET_PROPERTY_COMMUNITIES_FAILURE, GET_PROPERTY_COMMUNITIES_REQUEST, GET_PROPERTY_COMMUNITIES_SUCCESS } from "../constants/propertyConstants";
import { API_URL } from "../constants/config";

export const getcommunityApi=() =>async(dispatch)=>{
    try{
      dispatch({type: GET_COMMUNITY_GUIDELINES__REQUEST});
      const {data} = await axios.get(`${API_URL}/communites`);

      dispatch({

        type: GET_COMMUNITY_GUIDELINES__SUCCESS,
        payload: data,
      }); 
    }

    catch(error){
        dispatch({
            type: GET_COMMUNITY_GUIDELINES_FAILURE,
        });
    }
};



export const getCommunityCategoryApi=() =>async(dispatch)=>{
  try{
    dispatch({type: GET_COMMUNITY_CATEGORY__REQUEST});
    const {data} = await axios.get(`${API_URL}/comm_cat`);

    dispatch({

      type: GET_COMMUNITY_CATEGORY__SUCCESS,
      payload: data,
    }); 
  }

  catch(error){
      dispatch({
          type: GET_COMMUNITY_CATEGORY_FAILURE,
      });
  }
};

export const getSubCommunitiesInACommunityApi=(communityName) =>async(dispatch)=>{
  try{
    dispatch({type: GET_PROPERTY_COMMUNITIES_REQUEST});
    const {data} = await axios.get(`${API_URL}/properties/comm_subcomm/${communityName}`);

    dispatch({

      type: GET_PROPERTY_COMMUNITIES_SUCCESS,
      payload: data,
    }); 
  }

  catch(error){
      dispatch({
          type: GET_PROPERTY_COMMUNITIES_FAILURE,
      });
  }
};




export const getAllCommunitiesApi=() =>async(dispatch)=>{
  try{
    dispatch({type: GET_ALL_COMMUNITY_REQUEST});
    const {data} = await axios.get(`${API_URL}/communites`);
    dispatch({

      type: GET_ALL_COMMUNITY_SUCCESS,
      payload:data,
    }); 
  }

  catch(error){
      dispatch({
          type: GET_ALL_COMMUNITY_FAILURE,
      });
  }
};


export const getAllSubCommunitiesApi=(community_name) =>async(dispatch)=>{
  try{
    dispatch({type: GET_ALL_SUB_IN_COMMUNITY_REQUEST});
    const {data} = await axios.get(`${API_URL}/properties/comm_subcomm/${community_name}`);
    dispatch({

      type: GET_ALL_SUB_IN_COMMUNITY_SUCCESS,
      payload:data,
    }); 
  }

  catch(error){
      dispatch({
          type: GET_ALL_SUB_IN_COMMUNITY_FAILURE,
      });
  }
};


export const getAllTypesApi=() =>async(dispatch)=>{
  try{
    dispatch({type: GET_ALL_TYPE_REQUEST});
    const {data} = await axios.get(`${API_URL}/type`);
    dispatch({
     
      type: GET_ALL_TYPE_SUCCESS,
      payload:data,
    }); 
  }

  catch(error){
      dispatch({
          type: GET_ALL_TYPE_FAILURE,
      });
  }
};