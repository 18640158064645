export const GET_COMMUNITY_GUIDELINES__REQUEST="GET_COMMUNITY_GUIDELINES__REQUEST"
export const GET_COMMUNITY_GUIDELINES__SUCCESS="GET_COMMUNITY_GUIDELINES_REQUEST"
export const GET_COMMUNITY_GUIDELINES_FAILURE="GET_COMMUNITY_GUIDELINES_FAILURE"


export const GET_COMMUNITY_CATEGORY__REQUEST="GET_COMMUNITY_CATEGORY__REQUEST"
export const GET_COMMUNITY_CATEGORY__SUCCESS="GET_COMMUNITY_CATEGORY_REQUEST"
export const GET_COMMUNITY_CATEGORY_FAILURE="GET_COMMUNITY_CATEGORY_FAILURE"



export const GET_ALL_COMMUNITY_REQUEST="GET_ALL_COMMUNITY_REQUEST"
export const GET_ALL_COMMUNITY_SUCCESS="GET_ALL_COMMUNITY_SUCCESS"
export const GET_ALL_COMMUNITY_FAILURE="GET_ALL_COMMUNITY_FAILURE"




export const GET_ALL_SUB_IN_COMMUNITY_REQUEST="GET_ALL_SUB_IN_COMMUNITY_REQUEST"
export const GET_ALL_SUB_IN_COMMUNITY_SUCCESS="GET_ALL_SUB_IN_COMMUNITY_SUCCESS"
export const GET_ALL_SUB_IN_COMMUNITY_FAILURE="GET_ALL_SUB_IN_COMMUNITY_FAILURE"



export const GET_ALL_TYPE_REQUEST="GET_ALL_TYPE_REQUEST"
export const GET_ALL_TYPE_SUCCESS="GET_ALL_TYPE_SUCCESS"
export const GET_ALL_TYPE_FAILURE="GET_ALL_TYPE_FAILURE"
