import React, { useState } from "react";

const MarvelImages = () => {
  const images = [
    "https://www.arabianbusiness.com/cloud/2023/07/24/Dubai-real-estate.jpg",
    "https://www.arabianbusiness.com/cloud/2021/09/16/mhEZRclQ-Dubai-skyline-6.JPG",
    "https://www.arabianbusiness.com/cloud/2023/07/24/Dubai-real-estate.jpg",
    "https://www.arabianbusiness.com/cloud/2021/09/16/mhEZRclQ-Dubai-skyline-6.JPG",
    "https://www.arabianbusiness.com/cloud/2023/07/24/Dubai-real-estate.jpg",
    "https://www.arabianbusiness.com/cloud/2021/09/16/mhEZRclQ-Dubai-skyline-6.JPG",
    "https://www.arabianbusiness.com/cloud/2023/07/24/Dubai-real-estate.jpg",
    "https://www.arabianbusiness.com/cloud/2021/09/16/mhEZRclQ-Dubai-skyline-6.JPG",
    // Add more image URLs as needed
  ];
  const handleImageClick = (image) => {
    setSelectedImage(image);
  };
  let publicUrl = process.env.PUBLIC_URL + "/";

  const [selectedImage, setSelectedImage] = useState(images[0]);

  let touchStartX = 0;

  const handleTouchStart = (e) => {
    touchStartX = e.touches[0].clientX;
  };

  const handleTouchEnd = (e, image) => {
    const touchEndX = e.changedTouches[0].clientX;
    const touchThreshold = 50; // Adjust as needed

    if (touchStartX - touchEndX > touchThreshold) {
      // Swipe left, show the next image
      const currentIndex = images.findIndex(
        (img) => img.img_name === selectedImage
      );
      if (currentIndex < images.length - 1) {
        setSelectedImage(images[currentIndex + 1].img_name);
      }
    } else if (touchEndX - touchStartX > touchThreshold) {
      // Swipe right, show the previous image
      const currentIndex = images.findIndex(
        (img) => img.img_name === selectedImage
      );
      if (currentIndex > 0) {
        setSelectedImage(images[currentIndex - 1].img_name);
      }
    }
  };

  return (
    <div className="property-page-area  pd-bottom-90 ">
      <div className="container">
        <div className="property-details-top pd-bottom-70">
          <div className="col-lg-12 col-md-12 col-sm-12 p-3">
            {" "}
            {/* Add padding here */}
            <img
              src={selectedImage}
              alt="Selected Image"
              className="big-image img-fluid"
            />
          </div>

          <div className="single-thumbnail-slider">
            {images?.map((image, index) => (
              <div className="">
                <img
                  key={index}
                  src={image}
                  alt={`Image ${index}`}
                  className={`small-image ${
                    selectedImage === image ? "selected" : ""
                  }`}
                  onClick={() => handleImageClick(image)}
                  onTouchStart={handleTouchStart}
                  onTouchEnd={(e) => handleTouchEnd(e, image)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarvelImages;
