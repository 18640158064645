import React from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import PropertyDetailsPage from './section-components/property-details';
import Footer from './global-components/footer';

const PropertyDetails = ({propertyDetails}) => {
    return <div>
        <PropertyDetailsPage propertyDetails= {propertyDetails} />
        
        <Footer />
    </div>
}

export default PropertyDetails

