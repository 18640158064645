import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPropertiesNearByApi } from "../../../actions/propertiesActions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Col, Container, Row } from "react-bootstrap";

const NearByPlaces = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";

  const { loading, propertiesNearBy } = useSelector(
    (state) => state.propertiesNearBy
  );

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getPropertiesNearByApi(id));
  }, [dispatch, id]);

  const getIcon = (placeName) => {
    switch (placeName) {
      case "Hospital":
        return "fa fa-hospital";
      case "Bank":
        return "fa fa-bank";
      case "ATM":
        return "fa fa-credit-card-alt";
      case "School":
        return "fa fa-school";
      case "Burj Khalifa":
        return "fa fa-building";
      case "Beach":
        return "fa fa-water";
      case "Super Market":
        return "fa fa-shopping-basket";
      case "Burj Al Arab":
        return "fa fa-building";
      case "Pharmacy":
        return "fa fa-plus-square";
      case "Airport":
        return "fa fa-plane";
      case "Metro Station":
        return "fa fa-train";
      default:
        return "";
    }
  };

  return (
    <div className="single-property-grid">
      <h4>What's Nearby?</h4>
      {propertiesNearBy?.map((place, index) => (
        <div className="media single-review-inner" key={index}>
          <div className="media-left">
            <div className="thumb">
            <i className={getIcon(place?.near_by_place)} style={{ "color": "#FCC306" }}></i>

            </div>
          </div>
          <div className="media-body align-self-center">
            <Container>
              <Row>
              <div className="col-md-6">
                <h5>{place.near_by_place}</h5>
              </div>
              <div className="col-md-4 text-md-right">
                <p className="ratting-title">
                  {place.dist} km away
                </p>
           
              </div>
              </Row>
            
            </Container>
     
          
          </div>
        </div>
      ))}
    </div>
  );
};

export default NearByPlaces;
