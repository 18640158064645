import axios from "axios";
import { CATEGORY_FOR_SALE_OR_RENT_FAIL, CATEGORY_FOR_SALE_OR_RENT_REQUEST, CATEGORY_FOR_SALE_OR_RENT_SUCCESS, CATEGORY_PROPERTIES_APARTMENT_FAILURE, CATEGORY_PROPERTIES_APARTMENT_FAILURE_RENT, CATEGORY_PROPERTIES_APARTMENT_REQUEST, CATEGORY_PROPERTIES_APARTMENT_REQUEST_RENT, CATEGORY_PROPERTIES_APARTMENT_SUCCESS, CATEGORY_PROPERTIES_APARTMENT_SUCCESS_RENT, CATEGORY_PROPERTIES_FAILURE, CATEGORY_PROPERTIES_FAILURE_RENT, CATEGORY_PROPERTIES_PENTHOUSE_FAILURE, CATEGORY_PROPERTIES_PENTHOUSE_FAILURE_RENT, CATEGORY_PROPERTIES_PENTHOUSE_REQUEST, CATEGORY_PROPERTIES_PENTHOUSE_REQUEST_RENT, CATEGORY_PROPERTIES_PENTHOUSE_SUCCESS, CATEGORY_PROPERTIES_PENTHOUSE_SUCCESS_RENT, CATEGORY_PROPERTIES_REQUEST, CATEGORY_PROPERTIES_REQUEST_RENT, CATEGORY_PROPERTIES_SUCCESS, CATEGORY_PROPERTIES_SUCCESS_RENT, CATEGORY_PROPERTIES_TOWNHOUSE_FAILURE, CATEGORY_PROPERTIES_TOWNHOUSE_FAILURE_RENT, CATEGORY_PROPERTIES_TOWNHOUSE_REQUEST, CATEGORY_PROPERTIES_TOWNHOUSE_REQUEST_RENT, CATEGORY_PROPERTIES_TOWNHOUSE_SUCCESS, CATEGORY_PROPERTIES_TOWNHOUSE_SUCCESS_RENT } from "../constants/categoryConstants";
import { API_URL } from "../constants/config";

export const getCatgoryProperties = (propertyCategory) =>
    async (dispatch) => {
        try {
            dispatch({ type: CATEGORY_PROPERTIES_REQUEST });

            const  {data}  = await axios.get(`${API_URL}/properties/typefor/${propertyCategory}/sale`);


      

            dispatch({
                type: CATEGORY_PROPERTIES_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type:CATEGORY_PROPERTIES_FAILURE,
            });
        }
    };


    export const getCatgoryPropertiesRentApi = () =>
    async (dispatch) => {
        try {
            dispatch({ type: CATEGORY_PROPERTIES_REQUEST_RENT });

            const  {data}  = await axios.get(`${API_URL}/properties/typefor/villa/rent`);


            

            dispatch({
                type: CATEGORY_PROPERTIES_SUCCESS_RENT,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type:CATEGORY_PROPERTIES_FAILURE_RENT,
            });
        }
    };

    export const getTownhouseCatgoryProperties = () =>
    async (dispatch) => {
        try {
            dispatch({ type: CATEGORY_PROPERTIES_TOWNHOUSE_REQUEST });

            const  {data}  = await axios.get(`${API_URL}/properties/typefor/townhouse/sale`);


          

            dispatch({
                type: CATEGORY_PROPERTIES_TOWNHOUSE_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type:CATEGORY_PROPERTIES_TOWNHOUSE_FAILURE,
            });
        }
    };

    export const getTownhouseCatgoryRentProperties = () =>
    async (dispatch) => {
        try {
            dispatch({ type: CATEGORY_PROPERTIES_TOWNHOUSE_REQUEST_RENT });

            const  {data}  = await axios.get(`${API_URL}/properties/typefor/townhouse/rent`);


          

            dispatch({
                type: CATEGORY_PROPERTIES_TOWNHOUSE_SUCCESS_RENT,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type:CATEGORY_PROPERTIES_TOWNHOUSE_FAILURE_RENT,
            });
        }
    };



    export const getApartmentCatgoryProperties = () =>
    async (dispatch) => {
        try {
            dispatch({ type: CATEGORY_PROPERTIES_APARTMENT_REQUEST });

            const  {data}  = await axios.get(`${API_URL}/properties/typefor/apartment/sale`);


            

            dispatch({
                type: CATEGORY_PROPERTIES_APARTMENT_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type:CATEGORY_PROPERTIES_APARTMENT_FAILURE,
            });
        }
    };


    export const getApartmentCatgoryRentProperties = () =>
    async (dispatch) => {
        try {
            dispatch({ type: CATEGORY_PROPERTIES_APARTMENT_REQUEST_RENT });

            const  {data}  = await axios.get(`${API_URL}/properties/typefor/apartment/rent`);



            dispatch({
                type: CATEGORY_PROPERTIES_APARTMENT_SUCCESS_RENT,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type:CATEGORY_PROPERTIES_APARTMENT_FAILURE_RENT,
            });
        }
    };
    




    export const getPenthouseCatgoryProperties = () =>
    async (dispatch) => {
        try {
            dispatch({ type: CATEGORY_PROPERTIES_PENTHOUSE_REQUEST });

            const  {data}  = await axios.get(`${API_URL}/properties/typefor/penthouse/sale`);


          

            dispatch({
                type: CATEGORY_PROPERTIES_PENTHOUSE_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type:CATEGORY_PROPERTIES_PENTHOUSE_FAILURE,
            });
        }
    };


    export const getPenthouseCatgoryRentProperties = () =>
    async (dispatch) => {
        try {
            dispatch({ type: CATEGORY_PROPERTIES_PENTHOUSE_REQUEST_RENT });

            const  {data}  = await axios.get(`${API_URL}/properties/typefor/penthouse/rent`);



            dispatch({
                type: CATEGORY_PROPERTIES_PENTHOUSE_SUCCESS_RENT,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type:CATEGORY_PROPERTIES_PENTHOUSE_FAILURE_RENT,
            });
        }
    };


    export const getCatRentSale = (categary_type) =>
    async (dispatch) => {
        try {
            dispatch({ type: CATEGORY_FOR_SALE_OR_RENT_REQUEST });

            const  {data}  = await axios.get(`${API_URL}/categary/${categary_type}`);



            dispatch({
                type: CATEGORY_FOR_SALE_OR_RENT_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type:CATEGORY_FOR_SALE_OR_RENT_FAIL,
            });
        }
    };