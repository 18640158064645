
import React, { Component } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import OwlCarousel from 'react-owl-carousel';

class TestimonialV2 extends Component {
	render() {
		const testimonialData = [
			{
			  id: 1,
			  name: 'John Doe',
			  image: 'https://solverwp.com/demo/react/mingrand/assets/img/testimonial/1.png',
			  feedback: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
			},
			{
			  id: 2,
			  name: 'Jane Smith',
			  image: 'https://solverwp.com/demo/react/mingrand/assets/img/testimonial/4.png',
			  feedback: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
			},
			{
			  id: 3,
			  name: 'Alice Johnson',
			  image: 'https://solverwp.com/demo/react/mingrand/assets/img/testimonial/2.png',
			  feedback: 'Vestibulum in metus sed libero auctor finibus a et orci.',
			},
			{
			  id: 4,
			  name: 'Bob Williams',
			  image: 'https://solverwp.com/demo/react/mingrand/assets/img/testimonial/1.png',
			  feedback: 'Phasellus eu justo vel ex lacinia tincidunt at ac velit.',
			},
			// Add more testimonial data as needed
		  ];
	  const owlCarouselOptions = {
		loop: true,
		margin: 30,
		nav: true,
		dots: false,
		responsive: {
		  0: {
			items: 1, // Number of items to show on small screens
		  },
		  768: {
			items: 2, // Number of items to show on medium screens
		  },
		  1024: {
			items: 3, // Number of items to show on large screens
		  },
		},
	  };
  
	  return (
		<div className="testimonial-area bg-overlay pd-top-60 pd-bottom-120 bg-overlay mt-100">
		  <div className="container">
			<div className="style-white text-center">
			  <h6 className="testimonial">Our Testimonials</h6>
			  <h2 className="testimonial">What Clients Say</h2>
			  <p className="testimonial">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
			</div>
			<OwlCarousel className="testimonial-slider owl-carousel" {...owlCarouselOptions}>
			  {testimonialData.map((testimonial, index) => (
				<div className="item" key={index}>
				  <div className="single-testimonial-inner style-three text-center">
					<div className="thumb main-thumb">
					<LazyLoadImage
          src={testimonial.image}
          alt={testimonial.name}
		  effect="opacity"
          className="img-fluid"
        />					</div>
					<div className="designationTestimonial">
					  <h6 className="testimoialName">{testimonial.name}</h6>
					  <span className="designationTestimonial">{testimonial.designation}</span>
					  <p className="designationTestimonial">{testimonial.feedback}</p>
					  {/* Add your rating stars here */}
					</div>
				  </div>
				</div>
			  ))}
			</OwlCarousel>
		  </div>
		</div>
	  );
	}
  }
  
  export default TestimonialV2;
  