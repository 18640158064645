import React, { useEffect, useState } from 'react'
import NavbarV2 from '../../navbar-v2'
import MetaData from '../../../../layout/MetaData'
import { capitalizeWords, getPropertiesSubCommunity, getSubCommunitiesPropertiesUtils } from '../../../../utils'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import Loader from '../../../../layout/Loader/Loader'
import SubCommunityHeader from './SubCommunityHeader'
import LevelsInASubCommunity from './LevelsInASubCommunity'
import PropertyGrid from '../../../PropertyGrid'

const SubCommunityPage = () => {
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [propertySubCategory, setPropertySubCategory] = useState([])
    const [getLevelsInASubCommunity, setGetLevelsInASubCommunity] = useState([])
    const { property_for, property_city, property_community, property_type, property_sub_community } = useParams()
    const orignalSubCommunity = property_sub_community?.split("-").join(" ")
    const orignalCommunity=property_community?.split("-").join(" ")
    const { loading: communityloading, community } = useSelector((state) => state.community);
    const [propertyCategory, setpropertyCategory] = useState([])
    const [getSubCommunitiesInaCommunity, setGetSubCommunitiesInaCommunity] = useState([])
    const dispatch = useDispatch()
    let history = useHistory();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await getSubCommunitiesPropertiesUtils(property_city, property_type, property_for, orignalSubCommunity,orignalCommunity);
                setGetLevelsInASubCommunity(data);
                setLoading2(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await getPropertiesSubCommunity(property_type, property_for ,orignalSubCommunity);
                setPropertySubCategory(data);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (communityloading || loading) {
        return (
            <Loader />
        )
    }

    return (
        <>
            <NavbarV2 />
            <MetaData
                title={
                    ` ${capitalizeWords(property_type)} For ${capitalizeWords(
                        property_for
                    )} In ${capitalizeWords(orignalCommunity)}` +
                    `  ` +
                    `-` +
                    ` ` +
                    ` ` +
                    `Ya Homes Real Estate`
                }
                metaDesription={`Check Our Verified Listing Of Dubai ${capitalizeWords(
                    property_type
                )} For ${capitalizeWords(property_for)} In ${capitalizeWords(
                    orignalCommunity
                )} With World Class Amenities, Amazing Views And Attractive Lifestyle`}
            />

            <SubCommunityHeader city={capitalizeWords(property_city)} type={capitalizeWords(property_type)} for={capitalizeWords(property_for)} subComm={capitalizeWords(orignalSubCommunity)}  comm={capitalizeWords(orignalCommunity)} />

            {/* Conditionally render LevelsInASubCommunity if getLevelsInASubCommunity is not empty */}
            {getLevelsInASubCommunity.length > 0 && (
                <LevelsInASubCommunity subComm={orignalSubCommunity} getLevelsInASubCommunity={getLevelsInASubCommunity} />
            )}
{propertySubCategory.length > 0 && (
  <PropertyGrid related={true} categoryProperties={propertySubCategory} />
)}
        </>
    )
}

export default SubCommunityPage;
