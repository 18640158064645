import React, { useState } from "react";
import { PROPERTY_IMAGES_URL } from "../../constants/config";

const Images = ({ details, name }) => {

  const images=[

    "https://www.forsuperrich.com/wp-content/uploads/2019/01/LuxuryProperty.com-six-bedroom-penthouse-for-sales-in-23-Marina-Dubai-Marina-34.jpg",
    "https://luxhabitat.s3.ap-south-1.amazonaws.com/4772/1920/b09f6907684f37225e5fdde171622836334251f0fb110bd45e4383c32cbab9e7.jpg",
    "https://www1.lovethatdesign.com/wp-content/uploads/2018/09/20180912-Marina23Penthouse-01.jpg",
    "https://www.hauteresidence.com/wp-content/uploads/2018/09/Marina_23_Tower_6.jpg",
    "https://gregreport.com/wp-content/uploads/2020/07/03.jpg",
    "https://gregreport.com/wp-content/uploads/2020/07/13-1-1000x635.jpg",
    "https://cloudfront-eu-central-1.images.arcpublishing.com/thenational/E4JNIITMYBC7ZJSIML5EM54K6I.jpg",
    "https://image.khaleejtimes.com/?uuid=70155bf4-f02b-556b-9520-687ef8a8f8dc&function=cropresize&type=preview&source=false&q=75&crop_w=0.99999&crop_h=0.86042&width=1200&height=675&x=1.0E-5&y=0.13958",
    "https://baldorealtygroup.com/wp-content/uploads/2021/10/penthouse-10-1.jpg",
    "https://dubai-property.investments/uploads/images/2021-07/9051d046c675113f299b6788ed0cfbb7.jpeg"

  ]
  let publicUrl = process.env.PUBLIC_URL + "/";

  const [selectedImage, setSelectedImage] = useState(images[0]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  let touchStartX = 0;

  const handleTouchStart = (e) => {
    touchStartX = e.touches[0].clientX;
  };

  const handleTouchEnd = (e, image) => {
    const touchEndX = e.changedTouches[0].clientX;
    const touchThreshold = 50; // Adjust as needed

    if (touchStartX - touchEndX > touchThreshold) {
      // Swipe left, show the next image
      const currentIndex = images.findIndex(
        (img) => img.img_name === selectedImage
      );
      if (currentIndex < images.length - 1) {
        setSelectedImage(images[currentIndex + 1].img_name);
      }
    } else if (touchEndX - touchStartX > touchThreshold) {
      // Swipe right, show the previous image
      const currentIndex = images.findIndex(
        (img) => img.img_name === selectedImage
      );
      if (currentIndex > 0) {
        setSelectedImage(images[currentIndex - 1].img_name);
      }
    }
  };

  return (
    <div className="property-page-area pd-top-120 pd-bottom-90 ">
      <div className="container">
        <div className="property-details-top pd-bottom-70">
          <div className="property-details-top-inner">
            <div className="row">
              <div className="col-lg-7">
                <h3>{name}</h3>
                <p>
                  <img
                    src={publicUrl + "assets/img/icon/location2.png"}
                    alt="img"
                  />{" "}
                  {details?.property_community},
                  {details?.property_sub_community}{" "}
                </p>
                <ul>
                  <li>
                    {" "}
                    <span className="feature-icon">🛏️</span>
                    {details?.property_no_of_bedroom} bedroom
                  </li>
                  <li>
                    <span className="feature-icon">🚿</span>

                    {details?.property_no_of_bathroom} bathroom
                  </li>
                  <li>
                    <span className="feature-icon">📐</span> 

                    {details?.property_sq_ft}sq.ft
                  </li>
                </ul>
              </div>
              <div className="col-lg-5 text-lg-right">
                <h4>AED {details?.property_price}</h4>
                <div className="btn-wrap">
                  <a className="btn btn-base btn-sm" href="#">
                    BUILD
                  </a>
                  <a className="btn btn-blue btn-sm" href="#">
                    {details?.property_type}
                  </a>
                  <a className="btn btn-blue btn-sm" href="#">
                    {details?.property_for}
                  </a>
                </div>
                <ul>
                  <li>
                    <img src={publicUrl + "assets/img/icon/1.png"} alt="img" />
                    Marce 9 , 2020
                  </li>
                  <li>
                    <img src={publicUrl + "assets/img/icon/2.png"} alt="img" />
                    4263
                  </li>
                  <li>
                    <img src={publicUrl + "assets/img/icon/3.png"} alt="img" />
                    68
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 p-3">
            {" "}
            {/* Add padding here */}
            <img
              src={selectedImage}
              alt="Selected Image"
              className="big-image img-fluid"
            />
          </div>

		  <div className="single-thumbnail-slider">
            {images?.map((image, index) => (
				<div className="">
					    <img
                key={index}
                src={image}
                alt={`Image ${index}`}
                className={`small-image ${
                  selectedImage === image.img_name ? "selected" : ""
                }`}
                onClick={() => handleImageClick(image)}
                onTouchStart={handleTouchStart}
                onTouchEnd={(e) => handleTouchEnd(e, image)}
              />
				</div>
          
            ))}
          </div>
        </div>
		
      </div>
    </div>
  );
};

export default Images;
