export const GET_CONTACT_REQUEST="GET_CONTACT_REQUEST"
export const GET_CONTACT_SUCCESS="GET_CONTACT_SUCCESS"
export const GET_CONTACT_FAILURE="GET_CONTACT_FAILURE"




export const POST_CONTACT_REQUEST="POST_CONTACT_REQUEST"
export const POST_CONTACT_SUCCESS="POST_CONTACT_SUCCESS"
export const POST_CONTACT_FAILURE="POST_CONTACT_FAILURE"

