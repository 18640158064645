import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const BestVillas = () => {
  return (
    <Container className='mt-120'>
    <Row>
      <Col xs={12} md={6}>
        {/* Image goes here */}
        <div className="image-container">
        <LazyLoadImage
    src="https://d27k8xmh3cuzik.cloudfront.net/wp-content/uploads/2018/08/og-dubai-villas.jpg"
    alt="Luxurious Villa in Dubai"
    className="img-fluid hover-zoom"
  />
          </div>
       
      </Col>
      <Col xs={12} md={6}>
        {/* Content goes here */}
        <div>
        <h2>Best Villas in Dubai</h2>
          <p>
              Experience the epitome of luxury and sophistication with our
              handpicked selection of the finest villas in Dubai. Nestled in
              exclusive neighborhoods, these villas offer breathtaking views of
              the city skyline and the Arabian Gulf. Each villa is meticulously
              designed to provide you with a lavish retreat, featuring spacious
              living areas, private pools, and world-class amenities.
            </p>

        </div>
      </Col>
    </Row>
  </Container>  )
}

export default BestVillas