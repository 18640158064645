export const GET_ALL_TEAM_REQUEST="GET_ALL_TEAM_REQUEST"
export const GET_ALL_TEAM_SUCCESS="GET_ALL_TEAM_SUCCESS"
export const GET_ALL_TEAM_FAILURE="GET_ALL_TEAM_FAILURE"

export const GET_TEAM_DETAILS_REQUEST="GET_TEAM_DETAILS_REQUEST"
export const GET_TEAM_DETAILS_SUCCESS="GET_TEAM_DETAILS_SUCCESS"
export const GET_TEAM_DETAILS_FAILURE="GET_TEAM_DETAILS_FAILURE"



export const GET_CURRENT_TEAM_MEMBER_REQUEST="GET_CURRENT_TEAM_MEMBER_REQUEST"
export const GET_CURRENT_TEAM_MEMBER_SUCCESS="GET_CURRENT_TEAM_MEMBER_SUCCESS"
export const GET_CURRENT_TEAM_MEMBER_FAILURE="GET_CURRENT_TEAM_MEMBER_FAILURE"



export const GET_CURRENT_TEAM_MEMBER_ID="GET_CURRENT_TEAM_MEMBER_ID"



export const GET_AGENT_PROPERTIES_REQUEST="GET_AGENT_PROPERTIES_REQUEST"
export const GET_AGENT_PROPERTIES_SUCCESS="GET_AGENT_PROPERTIES_SUCCESS"
export const GET_AGENT_PROPERTIES_FAILURE="GET_AGENT_PROPERTIES_FAILURE"

