import React, { useEffect } from 'react'
import Navbar from '../global-components/navbar-v2';
import OffPlanBreadcrum from './PffPlan/OffPlanBreadcrum'
import { useDispatch, useSelector } from 'react-redux'
import { OffPlanDetailApi, getOffPlanCategoryApi } from '../../actions/offPlanActions'
import Loader from '../../layout/Loader/Loader'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { replaceHyWithSpacesToUppercase } from '../../utils'
import OffPlanCategories from './PffPlan/OffPlanCategories'
import Footer_v1 from './footer'
import Footer_v2 from './footer-v2'

const OffPlanProjects = () => {
   
	

  return (
    <>
                <Navbar />
                 <OffPlanBreadcrum /> 

                 <OffPlanCategories/>
                 <Footer_v2/>

    </>
  )
}

export default OffPlanProjects