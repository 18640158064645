export const GET_ALL_BLOG_REQUEST="GET_ALL_BLOG_REQUEST"
export const GET_ALL_BLOG_SUCCESS="GET_ALL_BLOG_SUCCESS"
export const GET_ALL_BLOG_FAILURE="GET_ALL_BLOG_FAILURE"

export const GET_BLOG_DETAILS_REQUEST="GET_BLOG_DETAILS_REQUEST"
export const GET_BLOG_DETAILS_SUCCESS="GET_BLOG_DETAILS_SUCCESS"
export const GET_BLOG_DETAILS_FAILURE="GET_BLOG_DETAILS_FAILURE"




export const GET_CURRENT_BLOG_ID='GET_CURRENT_BLOG_ID'