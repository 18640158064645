import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { LazyLoadImage } from "react-lazy-load-image-component";

class MoreInfo extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="blog-area pd-top-118">
        <div className="container">
          <div className="">
            <h6>More About Us</h6>
            <h2>More Info</h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="single-product-wrap style-2">
                <div className="thumb">
                  <LazyLoadImage
                  className="serviceImages"
				  effect="opacity"
				  alt={"New York Office"}
                    src={
                      "https://www.elliman.com/newyork/localimagereader.ashx?imageurl=https%3A%2F%2FRealEstateAdminImages.gabriels.net%2F526%2F4%2Fa939102f-64bd-4ffa-9342-91b8632c9216.jpg&imagecache=true"
                    } // use normal <img> attributes as props
                  />
                </div>
                <div className="product-details-inner">
                  <h4>New York Office</h4>
                  <p>
                    New York ipsum dolor consectetur adipisicing elit, sed do
                    eius Lorem ipsum dolo amet, consectetur eiusmod.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-product-wrap style-2">
                <div className="thumb">
				<LazyLoadImage
				  effect="opacity"
				  alt={"New York Office"}
          className="serviceImages"

                    src={
                      "https://media.truva.com/image/upload/t_large_image/t_invisible_watermark/ixhjcg8phcorzq6zalmp"
                    } // use normal <img> attributes as props
                  />
                 
                </div>
                <div className="product-details-inner">
                  <h4>Booston Office</h4>
                  <p>
                    Booston sum dolor consectetur adipisicing elit, sed do eius
                    Lorem ipsum dolo amet, consectetur adipisicing eiusmod.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-product-wrap style-2">
                <div className="thumb">
				<LazyLoadImage
				  effect="opacity"
          className="serviceImages"

                    alt={"New York Office"}
                    src={
                      "https://i2.moxi.onl/img-pr/i/e5f6baaa6e48d349d44e04f7e0294a353707f74b/0_3_full.jpg"
                    } // use normal <img> attributes as props
                  />
                </div>
                <div className="product-details-inner">
                  <h4>Canada Office</h4>
                  <p>
                    Canada orem ipsum dolor consectetur adipisicing elit, sed do
                    eius Lorem ipsum dolo amet, adipisicing eiusmod.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MoreInfo;
