import React, { useEffect } from "react";
import NavbarV2 from "./navbar-v2";
import { Card, Button, Row, Col, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getBlogsApi } from "../../actions/blogActions";
import Footer_v2 from "./footer-v2";

const ExploreDubai = () => {
  const { blogs } = useSelector((state) => state.blogs);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = (id, title) => {
    history.push(`/blog/${title?.toLowerCase().split(" ").join("-")}/${id}`);
  };
  useEffect(() => {
    dispatch(getBlogsApi());
  }, [dispatch]);

  
  const blogData = [
    {
      image: "image1.jpg",
      author: "Author 1",
      title: "Blog Title 1",
      date: "September 1, 2023",
      link: "/blog/1",
    },
    {
      image: "image2.jpg",
      author: "Author 2",
      title: "Blog Title 2",
      date: "September 5, 2023",
      link: "/blog/2",
    },
    {
      image: "image3.jpg",
      author: "Author 3",
      title: "Blog Title 3",
      date: "September 10, 2023",
      link: "/blog/3",
    },
  ];

  return (
    <>
      <NavbarV2 />
      <Container className="mt-120">
        <Row className="blog-grid">
          {blogs.map((blog, index) => (
            <Col key={index} lg={4} md={6} sm={12}>
              <Card className={`blog-card animate__animated animate__fadeIn`}>
                <div className="image-container">
                  <Card.Img
                    variant="top"
                    loading="lazy"
                    src={
                      "https://cdn.confident-group.com/wp-content/uploads/2022/06/10215842/Impact-On-Real-Estate.jpg"
                    }
                  />
                  <span className="card-date">{blog.date}</span>
                </div>
                <Card.Body className="card-body">
                  <Card.Text className="card-info">
                    <span className="card-author">By {blog.author}</span>
                  </Card.Text>
                  <Card.Title className="card-title">
                    <div className="title-text"></div> 
                  </Card.Title>
                  <Card.Text className="card-text">
                    {blog?.title}
                  </Card.Text>
                  <a
                    className="card-link"
                    onClick={() =>
                      handleClick(
                        blog?.id,

                        blog?.title
                      )
                    }
                  >
                    Read More
                  </a>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <Footer_v2 />
    </>
  );
};

export default ExploreDubai;
