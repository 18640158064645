import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPropertiesAmenitiesApi } from "../../../actions/propertiesActions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Col, Row } from "react-bootstrap";

const PropertyAmenities = () => {
  const { id } = useParams();
  const { loading, propertyAmenities } = useSelector(
    (state) => state.propertyAmenities
  );
  const dispatch = useDispatch();

  useEffect(() => dispatch(getPropertiesAmenitiesApi(id)), [dispatch]);

  // Helper function to group amenities into sets of three
  const groupAmenities = () => {
    const groups = [];
    for (let i = 0; i < propertyAmenities.length; i += 3) {
      groups.push(propertyAmenities.slice(i, i + 3));
    }
    return groups;
  };

  return (
    <div className="single-property-grid">
      <h4>Amenities</h4>
      {groupAmenities().map((group, index) => (
        <Row key={index}>
          {group.map((amenity, innerIndex) => (
            <Col md={4} lg={4} key={innerIndex}>
              <ul>
                <li>
                  <i className="fa fa-check" />
                  {amenity?.anmi_name}{" "}
                </li>
              </ul>
            </Col>
          ))}
        </Row>
      ))}
    </div>
  );
};

export default PropertyAmenities;
