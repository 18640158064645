import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import GoogleMap from '../../section-components/GoogleMap';
import AgentDetails from '../../section-components/AgentDetails';
import { useDispatch, useSelector } from 'react-redux';
import { getTeamDetailsApi } from '../../../actions/teamActions';
import { TEAM_API_URL } from '../../../constants/config';
import PropertyAmenities from './PropertyAminities';
import NearByPlaces from './NearByPlaces';
import { Col, Container, Row } from 'react-bootstrap';

const PropertyDescription = ({ details,description }) => {
  const [isExpanded, setIsExpanded] = useState(false);


  const dispatch = useDispatch();


  // Function to toggle the description
  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  // Render the description with HTML if expanded
  const renderedDescription = isExpanded ? (
    <div dangerouslySetInnerHTML={{ __html: description }} />
  ) : (
    <div
      dangerouslySetInnerHTML={{
        __html:
          description?.length > 200
            ? `${description.split(' ').slice(0, 200).join(' ')}...`
            : description,
      }}
    />
  );
  let publicUrl = process.env.PUBLIC_URL + "/";

  return (

    <Container>
      <Row>
        <Col>
        <div className="row go-top">
    <div className="col-lg-8">
      <div className="single-property-details-inner">
        <h4>Daily Apartment</h4>
        <div className="row">
        <div className="col-lg-12 col-12">{renderedDescription}</div>
      </div>
        {description?.length > 200 && (
        <button onClick={toggleDescription} className="read-more-link">
        {isExpanded ? 'Read Less' : 'Read More'}
        </button>
      )}
      

      
      <PropertyAmenities/>
      <NearByPlaces/>


        <div className="single-property-grid">
          <h4>Floor Plans</h4>
          <img
            src={"https://images.bayut.com/thumbnails/96574279-800x600.jpeg"}
            alt="img"

          />
        </div>
      
     
      </div>
    </div>
    <div className="col-lg-4">
      <aside className="sidebar-area">
        {
          details!==null &&
          <AgentDetails agentId={details?.property_agent_name}/>

        }
        <div className="widget widget-category">
          <h5 className="widget-title">Category</h5>
          <ul>
            <li>
              <a href="#">
                Design <span>26</span>
              </a>
            </li>
            <li>
              <a href="#">
                Villa House <span>20</span>
              </a>
            </li>
            <li>
              <a href="#">
                Business <span>21</span>
              </a>
            </li>
            <li>
              <a href="#">
                Global World <span>31</span>
              </a>
            </li>
            <li>
              <a href="#">
                Technology <span>12</span>
              </a>
            </li>
            <li>
              <a href="#">
                Ui Design <span>12</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="widget widget-place">
          <h5 className="widget-title">Place</h5>
          <ul>
            <li>
              New York <span>26</span>
            </li>
            <li>
              Love Road <span>20</span>
            </li>
            <li>
              Beach Side <span>21</span>
            </li>
            <li>
              Golden city <span>31</span>
            </li>
          </ul>
        </div>
        <div className="widget widget-news">
          <h5 className="widget-title">Popular Feeds</h5>
          <div className="single-news-wrap media">
            <div className="thumb">
              <img
                src={"https://solverwp.com/demo/react/mingrand/assets/img/blog/5.png"}
                alt="img"
              />
            </div>
            <div className="media-body align-self-center">
              <h6>
                <Link to="/blog-details">
                  Dolor eorem ipsum sit amet Lorem ipsum
                </Link>
              </h6>
              <p className="date">
                <i className="far fa-calendar-alt" />
                25 Aug 2020
              </p>
            </div>
          </div>
          <div className="single-news-wrap media">
            <div className="thumb">
              <img
                src={"https://solverwp.com/demo/react/mingrand/assets/img/blog/6.png"}
                alt="img"
              />
            </div>
            <div className="media-body align-self-center">
              <h6>
                <Link to="/blog-details">
                  Responsive Web And Desktop Develope
                </Link>
              </h6>
              <p className="date">
                <i className="far fa-calendar-alt" />
                25 Aug 2020
              </p>
            </div>
          </div>
          <div className="single-news-wrap media">
            <div className="thumb">
              <img
                src={"https://solverwp.com/demo/react/mingrand/assets/img/blog/7.png"}
                alt="img"
              />
            </div>
            <div className="media-body align-self-center">
              <h6>
                <Link to="/blog-details">
                  Admin Web is Django Highlig Models
                </Link>
              </h6>
              <p className="date">
                <i className="far fa-calendar-alt" />
                25 Aug 2020
              </p>
            </div>
          </div>
        </div>
        <div className="widget widget-tags">
          <h5 className="widget-title">Popular Tags</h5>
          <div className="tagcloud">
            <Link to="/blog">Popular</Link>
            <Link to="/blog">Art</Link>
            <Link to="/blog">Business</Link>
            <Link to="/blog">Design</Link>
            <Link to="/blog">Creator</Link>
            <Link to="/blog">CSS</Link>
            <Link to="/blog">Planing</Link>
            <Link to="/blog">Creative</Link>
          </div>
        </div>
      </aside>
    </div>
  </div>
        </Col>
      </Row>

    </Container>

  

  );
};

export default PropertyDescription;
