import axios from "axios";
import { API_URL } from "../constants/config";

export const removeSpaceAndJoin = (str) => {
  return str.toLowerCase().split(" ").join("-");
};


export const capitalizeFirstLetter=(string)=> {
  return string?.replace(/^\w/, (firstLetter) => firstLetter.toUpperCase());
}
export const capitalizeWords=(str)=> {
  return str.replace(/\b\w/g, char => char.toUpperCase());
}


export const url = (titleName) => {
  return titleName?.split(" ")?.join("-")?.toLowerCase();
};

const url2 = (titleName) => {
  return titleName?.split(" ")?.join("-")?.toLowerCase();
}; export const replaceHyphensWithSpaces = (str) => {
  return str.replace(/-/g, " ");
};
export const convertToLowercase = (str) => str.toLowerCase();
export const replaceHyWithSpacesToUppercase = (str) => {
  return str.split('-')
  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
  .join(' ');
};



export const getPropertiesTypeUtils= async (property_type, property_for, property_city) => {

  
  try {
    const response = await axios.get(`${API_URL}/properties/typefor/${replaceHyphensWithSpaces(property_type)}/${property_for}`);

    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch property types.');
  }
};


export const getPropertiesCategoryUtils= async (property_city,property_type,property_for,orignalCommunity) => {

  try {
    const response = await axios.get(`${API_URL}/properties/typeforcomm/${property_type}/${property_for}/${orignalCommunity}`);
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch property types.');
  }
};

export const getExclusivePropertiesInaCommunityUtils= async (property_for,orignalCommunity) => {
  https://totallyhomerealestate.com/API/api/properties/proforcomm/{for}/{comm}

    try {
      const response = await axios.get(`${API_URL}/properties/proforcomm/${property_for}/${orignalCommunity}`);
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch property types.');
    }
  };


  export const getSubCommunitiesInaCommunityUtils= async (property_type,property_for,orignalCommunity) => {


    try {
      const response = await axios.get(`${API_URL}/properties/comm_subcomm/${orignalCommunity}`);
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch property types.');
    }
  };



  export const replaceSpacesWithHyphensAndLowerCase = str => str.replace(/ /g, "-").toLowerCase();



  export const getSubCommunitiesPropertiesUtils= async (property_city,property_type,property_for,orignalSubCommunity) => {

    try {
      const response = await axios.get(`${API_URL}/properties/subcomm_level/${orignalSubCommunity}`);
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch property types.');
    }
  };


  export const getPropertiesSubCommunity= async (property_type, property_for ,orignalSubCommunity) => {

    try {
      const response = await axios.get(`${API_URL}/properties/typeforsubcomm/${property_type}/${property_for}/${orignalSubCommunity}`);
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch property types.');
    }
  };


  export const replaceHyphensAndCapitalize=(str)=> {
    const result = str.replace(/-/g, function(match) {
      return ' ';
    }).replace(/\b\w/g, function(match) {
      return match.toUpperCase();
    });
  
    return result;
  }






  export const getExclusivePropertiesInaSubCommunityUtils= async (property_for,orignalCommunity) => {
      
    
    try {
      const response = await axios.get(`${API_URL}/properties/proforsubcomm/${property_for}/${orignalCommunity}`);

      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch property types.');
    }
  };



  export const getExclusiveRelatedProperties= async (property_for,property_level) => {

    try {
      if(property_level){
        const response = await axios.get(`${API_URL}/properties/proforlevel/${property_for}/${property_level}`);
  
        return response.data;
      }
      if(property_for){
        const response = await axios.get(`${API_URL}/properties/profor/${property_for}`);
  
        return response.data;

      }
      
    } catch (error) {
      throw new Error('Failed to fetch property types.');
    }
  };
