import React from "react";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { PROPERTY_IMAGES_URL, TEAM_API_URL } from "../constants/config";
import { url } from "../utils";
import Loader from "../layout/Loader/Loader";
import { LazyLoadImage } from "react-lazy-load-image-component";

const PropertyGrid = ({ related, categoryProperties }) => {
  const truncateText = (text, maxWords) => {
    const words = text.split(" ");
    if (words.length <= maxWords) {
      return text;
    }
    const truncatedText = words.slice(0, maxWords).join(" ");
    return `${truncatedText}...`;
  };
  let publicUrl = process.env.PUBLIC_URL + "/";
  if (!categoryProperties?.length > 0) {
    return <Loader />;
  }
  return (
    <div className="propartes-area pd-top-118 pd-bottom-90">
      <div className="container">
        {related ? <h3>Related Properties</h3> : null}
        {categoryProperties?.length > 0 && (
          <Carousel
            additionalTransfrom={0}
            arrows
            centerMode={false}
            containerClass="carousel-container"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 3,
              },
              tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2,
              },
              mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
              },
            }}
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {categoryProperties?.map((item, index) => (
              <div className="col-lg-12 col-md-6" key={index}>
                <div className="single-product-wrap style-bottom">
                  <div className="thumb">
                    <LazyLoadImage
                      src="https://luxhabitat.s3.ap-south-1.amazonaws.com/4772/1920/b09f6907684f37225e5fdde171622836334251f0fb110bd45e4383c32cbab9e7.jpg"
                      alt="img"
                      className="propertyImage"
                      effect="opacity"
                    />
                    <div className="product-wrap-details">
                      <div className="media">
                        <div className="author">
                          <img
                            className="agentImage"
                            src={`${TEAM_API_URL}/${item?.path}`}
                            alt="img"
                          />
                        </div>
                        <div className="media-body">
                          <h6>
                            <Link to="/property">{"Rahil Hasan"}</Link>
                          </h6>
                          <p>
                            <img
                              src={
                                publicUrl + "assets/img/icon/location-alt.png"
                              }
                              alt="img"
                            />
                            New York real estate{" "}
                          </p>
                        </div>
                        <a className="fav-btn float-right" href="#">
                          <i className="far fa-heart" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="product-details-inner">
                    <h4 className="clamp-2-lines">
                      <Link
                        to={
                          item.property_level
                            ? `/${url(item?.property_city)}/${url(
                                item?.property_community
                              )}/${url(item?.property_sub_community)}/${url(
                                item?.property_type
                              )}-for-${url(item?.property_for)}-${url(
                                item?.property_level
                              )}/th${item?.id}`
                            : `/${url(item?.property_city)}/${url(
                                item?.property_community
                              )}/${url(item?.property_type)}-for-${url(
                                item?.property_for
                              )}-${url(item?.property_sub_community)}/th${
                                item?.id
                              }`
                        }
                      >
                        {item?.property_name}
                      </Link>
                    </h4>
                    <ul className="meta-inner">
                      <li>
                        <img
                          src={publicUrl + "assets/img/icon/location2.png"}
                          alt="img"
                        />
                        New York
                      </li>
                      <li>
                        <Link to="/property">For Sell</Link>
                      </li>
                    </ul>
                    <div
                      className="limited-paragraph "
                      dangerouslySetInnerHTML={{
                        __html: truncateText(item?.property_content, 40),
                      }}
                    />
                  </div>
                  <div className="product-meta-bottom">
                    <span className="price">AED {item?.property_price}</span>
                    <span>ref no: {item?.property_ref_no}</span>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        )}
      </div>
    </div>
  );
};

export default PropertyGrid;
