import axios from "axios";
import { GET_ABOUT_US_FAILURE, GET_ABOUT_US_REQUEST, GET_ABOUT_US_SUCCESS } from "../constants/aboutConstants";
import { API_URL } from "../constants/config";

export const getAboutUsApi = () => async (dispatch) => {
    try {
      dispatch({ type: GET_ABOUT_US_REQUEST});
  
      const  {data}  = await axios.get(`${API_URL}/abouts`);

  
      dispatch({
        type: GET_ABOUT_US_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_ABOUT_US_FAILURE,
      });
    }
  };
  