import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import {
  getPropertiesAmenitiesApi,
  getPropertiesImagesApi,
  getPropertiesNearByApi,
} from "../../actions/propertiesActions";
import { useParams } from "react-router-dom";
import { getTeamDetailsApi } from "../../actions/teamActions";
import AgentDetails from "./AgentDetails";
import GoogleMap from "./GoogleMap";
const PropertyDetails = ({ propertyDetails }) => {
  const getIcon = (placeName) => {
    switch (placeName) {
      case "Hospital":
        return "fa fa-hospital";
      case "Bank":
        return "fa fa-bank";

      case "ATM":
        return " fa fa-credit-card-alt";

      case "School":
        return "fa fa-school";

      case "Burj Khalifa":
        return "fa fa-building";

      case "Beach":
        return "fa fa-water";

      case "Super Market":
        return "fa fa-shopping-basket";

      case "Burj Al Arab":
        return "fa fa-building";
      case "Pharmacy":
        return " fa fa-plus-square ";

      case "Airport":
        return "fa fa-plane";

      case "Metro Station":
        return "fa fa-train";
    }
  };
  const { teamDetails } = useSelector((state) => state?.teamDetails);

  const { laoding, propertiesNearBy } = useSelector(
    (state) => state.propertiesNearBy
  );

  const { loading: l2, propertyAmenities } = useSelector(
    (state) => state.propertyAmenities
  );

  let publicUrl = process.env.PUBLIC_URL + "/";

  const [carouselImages, setCarouselImages] = useState([
    publicUrl + "assets/img/project-single/1.png",
    publicUrl + "assets/img/project-single/2.png",
    publicUrl + "assets/img/project-single/3.png",
    publicUrl + "assets/img/project-single/4.png",
    publicUrl + "assets/img/project-single/5.png",
    // Add more images here
  ]);
  const { loading, propertyImages } = useSelector(
    (state) => state.propertyImages
  );
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getPropertiesImagesApi(id));
    dispatch(getPropertiesAmenitiesApi(id));
    dispatch(getPropertiesNearByApi(id));
   
  }, [dispatch]);
  useEffect(() => {
    const $ = window.$;
    $("body").addClass("body-bg");
  }, []);


  return (
    <div className="property-page-area pd-top-120 pd-bottom-90 ">
      <div className="container">
        <div className="row go-top">
          <div className="col-lg-8">
            <div className="single-property-details-inner">
              <h4>Property Decription</h4>
              <p
                dangerouslySetInnerHTML={{
                  __html: propertyDetails?.property_content,
                }}
              />

              <div className="single-property-grid">
                <h4>Poperty Details</h4>
                <div className="row">
                  <div className="col-md-4">
                    <ul>
                      <li>
                        Bedrooms: {propertyDetails?.property_no_of_bedroom}
                      </li>
                      <li>
                        Bathrooms: {propertyDetails?.property_no_of_bathroom}
                      </li>
                      <li>Type: {propertyDetails?.property_for}</li>
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <ul>
                      <li>Area: {propertyDetails?.property_sq_ft}sqft.</li>
                      <li>Area: {propertyDetails?.property_sq_ft}sqft.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="single-property-grid">
                <h4>Amenities</h4>
                <div className="row">
                  {propertyAmenities?.slice(0, 3).map((amenity, index) => (
                    <div className="col-md-4">
                      <ul>
                        <li>
                          <i className="fa fa-check" />
                          {amenity?.anmi_name}
                        </li>
                      </ul>
                    </div>
                  ))}
                  {propertyAmenities?.slice(3, 6).map((amenity, index) => (
                    <div className="col-md-4">
                      <ul>
                        <li>
                          <i className="fa fa-check" />
                          {amenity?.anmi_name}
                        </li>
                      </ul>
                    </div>
                  ))}
                  {propertyAmenities?.slice(6, 9).map((amenity, index) => (
                    <div className="col-md-4">
                      <ul>
                        <li>
                          <i className="fa fa-check" />
                          {amenity?.anmi_name}
                        </li>
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
              <div className="single-property-grid">
                <h4>Nearby Places</h4>
                <div className="row">
                  {propertiesNearBy?.slice(0, 3)?.map((nearby, index) => (
                    <div className="col-md-4">
                      <i
                        className={getIcon(nearby?.near_by_place)}
                        style={{ color: "red" }}
                      ></i>
                      <label className="checkbox-item sizing420">
                        {nearby?.near_by_place} - {nearby?.dist} Minutes
                      </label>
                    </div>
                  ))}

                  {propertiesNearBy?.slice(3, 6)?.map((nearby, index) => (
                    <div className="col-md-4">
                      <i
                        className={getIcon(nearby?.near_by_place)}
                        style={{ color: "red" }}
                      ></i>
                      <label className="checkbox-item sizing420">
                        {nearby?.near_by_place} - {nearby?.dist} Minutes
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="single-property-grid">
                <h4>Proparty Attachment</h4>
                <div className="row">
                  <div className="col-sm-6">
                    <a href="PDFLINK" download>
                      <img
                        src={publicUrl + "assets/img/icon/9.png"}
                        alt="img"
                      />
                    </a>
                  </div>
                  <div className="col-sm-6 mt-2 mt-sm-0">
                    <a href="PDFLINK" download>
                      <img
                        src={publicUrl + "assets/img/icon/9.png"}
                        alt="img"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="single-property-grid">
                <h4>Estate Location</h4>
                <div className="property-map">
                <GoogleMap propertyDetails={propertyDetails}/>
                </div>
              </div>
              <div className="single-property-grid">
                <h4>Floor Plans</h4>
                <img
                  src={publicUrl + "assets/img/project-single/6.png"}
                  alt="img"
                />
              </div>
              <div className="single-property-grid">
                <h4>Intro Video</h4>
                <div
                  className="property-video text-center"
                  style={{
                    background:
                      "url(" + publicUrl + "assets/img/project-single/8.png)",
                  }}
                >
                  <a
                    className="play-btn"
                    href="https://www.youtube.com/embed/Wimkqo8gDZ0"
                    data-effect="mfp-zoom-in"
                  >
                    <i className="fa fa-play" aria-hidden="true" />
                  </a>
                </div>
              </div>
              <div className="single-property-grid">
                <h4>Whats Nearby?</h4>
                <div className="media single-review-inner">
                  <div className="media-left">
                    <div className="thumb">
                      <img
                        src={publicUrl + "assets/img/project-single/9.png"}
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="media-body align-self-center">
                    <div className="row">
                      <div className="col-md-8">
                        <h5>Eureka/Harvey Milk Branch</h5>
                        <p>consectetuLorem ipsum dolor sit amet</p>
                      </div>
                      <div className="col-md-4 text-md-right">
                        <p className="ratting-title">
                          <span>32</span> Reviews
                        </p>
                        <div className="ratting-inner">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="media single-review-inner">
                  <div className="media-left">
                    <div className="thumb">
                      <img
                        src={publicUrl + "assets/img/project-single/10.png"}
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="media-body align-self-center">
                    <div className="row">
                      <div className="col-md-8">
                        <h5>Milbaly Extension &amp; Academy</h5>
                        <p>consectetuLorem ipsum dolor sit amet</p>
                      </div>
                      <div className="col-md-4 text-md-right">
                        <p className="ratting-title">
                          <span>32</span> Reviews
                        </p>
                        <div className="ratting-inner">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="media single-review-inner">
                  <div className="media-left">
                    <div className="thumb">
                      <img
                        src={publicUrl + "assets/img/project-single/11.png"}
                        alt="img"
                      />
                    </div>
                  </div>
                  <div className="media-body align-self-center">
                    <div className="row">
                      <div className="col-md-8">
                        <h5>Nilgao School</h5>
                        <p>consectetuLorem ipsum dolor sit amet</p>
                      </div>
                      <div className="col-md-4 text-md-right">
                        <p className="ratting-title">
                          <span>32</span> Reviews
                        </p>
                        <div className="ratting-inner">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="single-property-grid">
                <h4>Page statistics</h4>
                <img
                  src={publicUrl + "assets/img/project-single/7.png"}
                  alt="img"
                />
              </div>
              <form className="single-property-comment-form">
                <div className="single-property-grid bg-black">
                  <div className="single-property-form-title">
                    <div className="row">
                      <div className="col-md-8">
                        <h4>Post Your Comment</h4>
                      </div>
                      <div className="col-md-4 text-md-right">
                        <div className="ratting-inner">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <label className="single-input-inner style-bg">
                        <span className="label">Enter Your Name</span>
                        <input type="text" placeholder="Your name here...." />
                      </label>
                    </div>
                    <div className="col-lg-6">
                      <label className="single-input-inner style-bg">
                        <span className="label">Enter Your MAil</span>
                        <input type="text" placeholder="Your email here...." />
                      </label>
                    </div>
                    <div className="col-12">
                      <label className="single-input-inner style-bg">
                        <span className="label">Enter Your Messege</span>
                        <textarea
                          placeholder="Enter your messege here...."
                          defaultValue={""}
                        />
                      </label>
                    </div>
                    <div className="col-12 mb-4">
                      <button className="btn btn-base radius-btn">
                        Submit Now
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
		  {
								propertyDetails?.property_agent_name &&
								<AgentDetails agent={propertyDetails?.property_agent_name}/>

							}
        </div>
      </div>
    </div>
  );
};

export default PropertyDetails;
