import React, { useState } from "react";
import { Container, Row, Col, Card, ProgressBar } from "react-bootstrap";
import { ABOUT_IMAGE_URL } from "../../constants/config";

const WorkWithUs = ({ career }) => {
  const recruitmentSteps = [
    { step: 'Step 1', title: 'Application Review', description: 'Review incoming job applications and resumes.' },
    { step: 'Step 2', title: 'Interviews', description: 'Conduct interviews with qualified candidates.' },
    { step: 'Step 3', title: 'Assessment', description: 'Administer skills assessments and evaluations.' },
    { step: 'Step 4', title: 'Offer', description: 'Extend offers to candidates who have successfully completed the interview' },
];
    const workplaceDescription =
    'We support our employees by not only compensating them lucratively for the work they do, but by constantly having their backs and bolstering their daily working lives through a range of benefits and initiatives.';

    const initiatives = [
      'Flexible work arrangements that allow employees to create a better work-life balance and accommodate personal commitments.',
      'Collaborative team environment that encourages open communication, knowledge sharing, and cross-functional collaboration on projects.',
      'Employee well-being programs focused on physical health, mental wellness, and stress management to ensure a healthy and happy workforce.',
      'Generous paid time off policies that include vacation days, holidays, and sick leave to ensure employees have time to relax and recharge.',
      // Add more initiatives here
  ];

const [expanded, setExpanded] = useState(false);

const toggleExpansion = () => {
    setExpanded(!expanded);
};

    const perks = [
        {
            title: 'Flexible Work Hours',
            description: 'We offer flexible work hours to ensure a work-life balance for our team.',
        },
        {
            title: 'Professional Development',
            description: 'We support your career growth with opportunities for learning and development.',
        },
        {
            title: 'Collaborative Environment',
            description: 'Join a collaborative and supportive team environment.',
        },
        {
            title: 'Flexible Work Hours',
            description: 'We offer flexible work hours to ensure a work-life balance for our team.',
        },
        {
            title: 'Professional Development',
            description: 'We support your career growth with opportunities for learning and development.',
        },
        {
            title: 'Collaborative Environment',
            description: 'Join a collaborative and supportive team environment.',
        },
        // Add more perks here
    ];
  const cardItems = [
    {
      title: "You Value Expertise",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus dapibus felis sed lacus ultricies.",
    },
    {
      title: "You Seek Personalized Solutions",
      description:
        "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.",
    },
    {
      title: "You Aspire for Prime Properties",
      description:
        "Nulla facilisi. Sed convallis tincidunt justo, non iaculis urna iaculis et. Nam ac ex et turpis placerat interdum.",
    },
    {
      title: "You Appreciate Transparency",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus dapibus felis sed lacus ultricies.",
    },
    {
      title: "You Value Long-term Relationships",
      description:
        "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.",
    },
    {
      title: "You Demand Quality Services",
      description:
        "Nulla facilisi. Sed convallis tincidunt justo, non iaculis urna iaculis et. Nam ac ex et turpis placerat interdum.",
    },
  ];
  return (
    <div className="our-approach-section py-5">
      {career?.map((item, index) => (
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <img
                src={`${ABOUT_IMAGE_URL}/${item?.work_img}`}
                alt="Our Approach"
                className="img-fluid"
                loading="lazy"
              />
            </Col>
            <Col md={6}>
              <div className="approach-text">
                <h5>Work with us</h5>
                <h3>{item?.work_h}</h3>
                <p>{item?.work_dec}</p>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center mt-120">
            <Col md={6}>
              <div className="approach-text">
                <h3>Join Us</h3>
                <p>{item?.join_dsc}</p>
              </div>
            </Col>
            <Col md={6}>
              <img
                src={`${ABOUT_IMAGE_URL}/${item?.join_img}`}
                alt="Our Approach"
                className="img-fluid"
              />
            </Col>
          </Row>

          <div className="right-fit-section mt-120">
            <Container>
              <h2 className="text-center mb-4">
                You’re The Right Fit For Totally Home Real Estate, If:
              </h2>
              <Row>
                {cardItems.map((item, index) => (
                  <Col md={4} key={index}>
                    <Card className="custom-card mb-4">
                      <Card.Body>
                        <Card.Title>{item.title}</Card.Title>
                        <Card.Text>{item.description}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Container>
          </div>

          <Row className="align-items-center mt-120">
            <Col md={6}>
              <div className="approach-text">
                <h3>{item?.sec4_h}</h3>
                <p>{item?.sec4_dsc}</p>
              </div>
            </Col>
            <Col md={6}>
              <img
                src={`${ABOUT_IMAGE_URL}/${item?.sec4_dsc}`}
                alt="Our Approach"
                className="img-fluid"
              />
            </Col>
          </Row>



          <Row className="align-items-center mt-120">
          <Col md={6}>
              <img
                src={`${ABOUT_IMAGE_URL}/${item?.sec5_dsc}`}
                alt="Our Approach"
                className="img-fluid"
              />
            </Col>
            <Col md={6}>
              <div className="approach-text">
                <h3>{item?.sec5_h}</h3>
                <p>{item?.sec5_dsc}</p>
              </div>
            </Col>
           
          </Row>

          <div className="perks-section mt-120">
            <Container>
                <h2 className="text-center mb-4">Perks of Joining Ya Homes Real Estate</h2>
                <Row>
                    {perks.map((perk, index) => (
                        <Col md={4} key={index} className="mb-4">
                            <Card className="custom-card mb-4">
                                <Card.Body>
                                    <Card.Title>{perk.title}</Card.Title>
                                    <Card.Text>{perk.description}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>



        <div className="workplace-section mt-120">
            <Container>
                <h2 className="text-center mb-4">What Makes Us a Great Workplace</h2>
                <p>
                    {expanded ? workplaceDescription : workplaceDescription.substring(0, 70)}
                    {workplaceDescription.length > 70 && (
                        <span onClick={toggleExpansion} className="read-more-link">
                            {expanded ? 'Read Less' : 'Read More'}
                        </span>
                    )}
                </p>
                <Row>
                    {initiatives.map((initiative, index) => (
                        <Col md={3} key={index} className="mb-4">
                            <Card className="custom-card">
                                <Card.Body>
                                    <Card.Text>{initiative}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>


        <Row className="align-items-center mt-120">
            <Col md={6}>
              <div className="approach-text">
                <h3>{item?.sec9_h}</h3>
                <p>{item?.sec9_dsc}</p>
              </div>
            </Col>
            <Col md={6}>
              <img
                src={`${ABOUT_IMAGE_URL}/${item?.sec9_img}`}
                alt="Our Approach"
                className="img-fluid"
              />
            </Col>
          </Row>
        </Container>
      ))}
        <div className="recruitment-section bg-light mt-120 py-5">
            <Container>
                <h2 className="text-center mb-4">Recruitment Process</h2>
                <Row>
                    {recruitmentSteps.map((step, index) => (
                        <Col key={index} md={3} className="mb-4">
                            <div className="text-center">
                                <div className="step-circle">{step.step}</div>
                                <h4 className="mt-3">{step.title}</h4>
                                <p className="mb-4">{step.description}</p>
                                <ProgressBar now={(index + 1) * 25} />
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    </div>
  );
};

export default WorkWithUs;
