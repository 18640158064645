export const GET_OFF_PLAN_REQUEST='GET_OFF_PLAN_REQUEST'
export const GET_OFF_PLAN_SUCCESS='GET_OFF_PLAN_SUCCESS'
export const GET_OFF_PLAN_FAIL='GET_OFF_PLAN_FAIL'




export const GET_OFFPLAN_CATEGORY_REQUEST='GET_OFFPLAN_CATEGORY_REQUEST'
export const GET_OFFPLAN_CATEGORY_SUCCESS='GET_OFFPLAN_CATEGORY_SUCCESS'
export const GET_OFFPLAN_CATEGORY_FAILURE='GET_OFFPLAN_CATEGORY_FAILURE'

export const GET_OFFPLAN_DETAIL_REQUEST='GET_OFFPLAN_DETAIL_REQUEST'
export const GET_OFFPLAN_DETAIL_SUCCESS='GET_OFFPLAN_DETAIL_SUCCESS'
export const GET_OFFPLAN_DETAIL_FAILURE='GET_OFFPLAN_DETAIL_FAILURE'

