import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCatgoryProperties } from "../../actions/catgoryActions";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { TEAM_API_URL } from "../../constants/config";
import PropertyGrid from "../PropertyGrid";

const Product = () => {
  const { categoryProperties } = useSelector(
    (state) => state.categoryProperties
  );
  const [propertyCategory, setPropertyCategory] = useState("villa");

  let publicUrl = process.env.PUBLIC_URL + "/";
  const dispatch = useDispatch();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  useEffect(() => {
    dispatch(getCatgoryProperties(propertyCategory));
  }, [dispatch]);

  const url = (titleName) => {
    return titleName?.split(" ")?.join("-")?.toLowerCase();
  };
  const url2 = (titleName) => {
    return titleName?.split(" ")?.join("-")?.toLowerCase();
  };
  return (
    
  

        <PropertyGrid categoryProperties={categoryProperties}/>
       
    
  );
};

export default Product;
