import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import ReCAPTCHA from "react-google-recaptcha";

function ContactForm() {
  const [formData, setFormData] = useState({
    subject: "",
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const customLatitude = 25.1854714; // Replace with your desired latitude
  const customLongitude = 55.2742347; // Replace with your desired longitude
  
  const [errors, setErrors] = useState({});
  const [captchaValue, setCaptchaValue] = useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Clear the error message when the user types in a field
    setErrors({ ...errors, [name]: "" });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the form fields
    const newErrors = {};
    if (!formData.subject) newErrors.subject = "Subject is required";
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.phone) newErrors.phone = "Phone is required";
    if (!formData.message) newErrors.message = "Message is required";
    if (!captchaValue) newErrors.captcha = "Please complete the CAPTCHA";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // If all validation passes, submit the form (you can handle this part)

    // Reset the form and captcha after successful submission
    setFormData({
      subject: "",
      name: "",
      email: "",
      phone: "",
      message: "",
    });
    setCaptchaValue(null);
  };

  useEffect(() => {
    const $ = window.$;
    $(".footer-area.style-two").removeClass("mg-top-100");
  }, []);

  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div className="contact-page-area pd-top-120">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-7 mb-5 mb-lg-0">
            <div className="contact-details-inner mng-box-shadow">
              <h4>Adipisicing elit se tempor labore .</h4>
              <p>
                Lorem ipsum dolor consectetur aLorem ipsum consectetur
                adipisicing elit, eiusmod tempor incididunt labore et dolore
                magna aliqua.minim veniam, quis nostrud exercitation ullamco
                laboris nisi ut aliquip ex ea commodo
              </p>
              <div className="row">
                <div className="col-md-6">
                  <div className="contact-single-list">
                    <h5>Dubai Office</h5>
                    <ul>
                      <li>
                        <img
                          src={publicUrl + "assets/img/icon/location2.png"}
                          alt="img"
                        />{" "}
1209 Burlington Tower Business Bay Dubai                      </li>
                      <li>
                        <img
                          src={publicUrl + "assets/img/icon/location2.png"}
                          alt="img"
                        />{" "}
                        +0544977227
                      </li>
                   
                    </ul>
                  </div>
                </div>
               
              </div>
              <div className="row mt-4">
                <div className="col-md-6">
                  <div className="contact-single-date">
                    <p>
                      <strong>Monday-Friday:</strong> 9am - 8pm
                    </p>
                    <p>
                      <strong>Saturday:</strong> 10 am to 3 pm
                    </p>
                    <p>
                      <strong>Sunday: </strong> Clossed
                    </p>
                  </div>
                </div>
                <div className="col-md-6 align-self-center text-md-right">
                  <ul className="social-area style-3">
                    <li>
                      <a>
                        <i className="fab fa-facebook-f" aria-hidden="true" />
                      </a>
                    </li>
                    <li>
                      <a>
                        <i className="fab fa-twitter" aria-hidden="true" />
                      </a>
                    </li>
                    <li>
                      <a >
                        <i className="fab fa-instagram" aria-hidden="true" />
                      </a>
                    </li>
                    <li>
                      <a >
                        <i className="fab fa-skype" aria-hidden="true" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-5">
            <form onSubmit={handleSubmit}>
              <div className="row">
               
                <div className="col-lg-6 col-md-6">
                  <label className="single-input-inner style-bg-border">
                    <input
                      type="text"
                      name="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {errors.name && (
                      <span className="error">{errors.name}</span>
                    )}
                  </label>
                </div>
                <div className="col-xl-12 col-lg-6">
                  <label className="single-input-inner style-bg-border">
                    <input
                      type="text"
                      name="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <span className="error">{errors.email}</span>
                    )}
                  </label>
                </div>
                <div className="col-md-6">
                  <label className="single-input-inner style-bg-border">
                    <input
                      type="text"
                      name="phone"
                      placeholder="Phone"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                    {errors.phone && (
                      <span className="error">{errors.phone}</span>
                    )}
                  </label>
                </div>
                <div className="col-md-6">
                  <label className="single-input-inner style-bg-border">
                    <input
                      type="text"
                      name="message"
                      placeholder="Message"
                      value={formData.message}
                      onChange={handleChange}
                    />
                    {errors.message && (
                      <span className="error">{errors.message}</span>
                    )}
                  </label>
                </div>
                <div className="col-12 mb-4">
                  <ReCAPTCHA
                    sitekey="6LcM6ycoAAAAAHto-ZQ3LASBGX866X1Ccgxt-fDq"
                    onChange={handleCaptchaChange}
                  />
                  {errors.captcha && (
                    <span className="error">{errors.captcha}</span>
                  )}
                </div>
                <div className="col-12 mb-4">
                  <button type="submit" className="btn btn-base">
                    Submit Now
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="contact-page-map mg-top-100">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d198059.49240377638!2d${customLongitude}!3d${customLatitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1615660592820!5m2!1sen!2sbd" />
      </div>
    </div>
  );
}

export default ContactForm;
