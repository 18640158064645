import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class Video extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="video-area bg-overlay mt-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-10">
              <div className="text-center">
                <h2 className="testimonial">Best Apartments In Dubai</h2>
                <p className="testimonial">
                  Best apartment in Dubai provides a variety of advantages for
                  individuals who are looking for the apartments for sale or
                  apartments for rent in Dubai, such as ease of commuting,
                  privacy, and low cost of living. Dubai hosts various
                  communities such as Downtown Dubai, Dubai Marina, Palm
                  Jumeirah, Emaar Beachfront, and the Bluewaters which provide
                  luxury apartments of different sizes, shapes, and prices. You
                  can access these apartments according to your needs - whether
                  you need it to be near the beach for easier access to the
                  beach, and let's say an ideal location for your family, or you
                  would like to live in a quiet area in Downtown Dubai. The
                  various residencies offer 2-5 bedroom apartments, where you
                  can choose from various scenic locations, unrivaled views,
                  stylish designs, and exceptional facilities. You can also
                  enjoy a balanced lifestyle in Downtown Dubai as your apartment
                  can provide you with all the amenities you need such as bars,
                  restaurants, gyms, swimming pools, and spa centers. Apartments
                  are easy to find as well. Thanks to the presence of different
                  communities such as Downtown Dubai, Business Bay and Palm
                  Jumeirah, you don't have to search too hard for the one that
                  fits all your needs
                </p>
				<a
  className="play-btn zoom-animation"
  href="https://www.youtube.com/watch?v=hs5VVFbPpDY"
  data-effect="mfp-zoom-in"
>
  <i className="fa fa-play" style={{ color: '#FCC306' }} />
</a>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Video;
