import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

const TypePropertyContent = () => {
  return (
    <div className="section">
      <Container>
        <Row>
          {/* Left Part */}
          <Col lg={6}>
            <div className="left-content">
              <h2>Live in style and comfort with our spacious townhouses</h2>
              <p>
                Over 39,000 people work for us in more than 70 countries all over the
                This breadth of global coverage, combined with specialist services
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
              </p>
            </div>
          </Col>

          {/* Right Part */}
          <Col lg={6}>
            <Row>
              {/* Card 1 */}
              <Col lg={6} sm={6}>
                <Card className="custom-card2">
                  <Card.Img loading='lazy' src="https://thepenthouse.co/wp-content/uploads/2022/05/dubai-1.jpg" alt="Card 1" />
                  <Card.ImgOverlay className="text-center custom-overlay">
                    <Card.Title>Card Title 1</Card.Title>
                  </Card.ImgOverlay>
                </Card>
              </Col>

              {/* Card 2 */}
              <Col lg={6} sm={6} >
                <Card className="custom-card2">
                  <Card.Img loading='lazy' src="https://thepenthouse.co/wp-content/uploads/2022/05/dubai-1.jpg" alt="Card 2" />
                  <Card.ImgOverlay className="text-center custom-overlay">
                    <Card.Title>Card Title 2</Card.Title>
                  </Card.ImgOverlay>
                </Card>
              </Col>
            </Row>

            <Row>
              {/* Card 3 */}
              <Col lg={6} sm={6}>
                <Card className="custom-card2">
                  <Card.Img loading='lazy' src="https://thepenthouse.co/wp-content/uploads/2022/05/dubai-1.jpg" alt="Card 3" />
                  <Card.ImgOverlay className="text-center custom-overlay">
                    <Card.Title>Card Title 3</Card.Title>
                  </Card.ImgOverlay>
                </Card>
              </Col>

              {/* Card 4 */}
              <Col lg={6} sm={6}>
                <Card className="custom-card2">
                  <Card.Img loading='lazy' src="https://thepenthouse.co/wp-content/uploads/2022/05/dubai-1.jpg" alt="Card 4" />
                  <Card.ImgOverlay className="text-center custom-overlay">
                    <Card.Title>Card Title 4</Card.Title>
                  </Card.ImgOverlay>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TypePropertyContent;
