import React, { useEffect, useMemo } from 'react';
import Banner from './section-components/banner';
import Service from './section-components/service';
import Product from './section-components/product';
import Video from './section-components/video';
import ProductV2 from './section-components/product-v2';
import Testimonial from './section-components/testimonial';
import Property from './section-components/property';
import Cta from './section-components/call-to-action';
import Agent from './section-components/agent';
import Client from './section-components/client';
import LatestNews from './blog-components/latest-news';
import { useDispatch, useSelector } from 'react-redux';
import { getHomePageApi } from '../actions/homePageActions';
import { useParams } from 'react-router-dom';
import { getAllCommunitiesApi } from '../actions/communityActions';
import { getAllPropertiesApi } from '../actions/propertiesActions';
import MetaData from '../layout/MetaData';
import OurTeam from './OurTeam';
import BestVillas from './section-components/BestVillas';
import ApartmentProduct from './section-components/ApartmentProduct';
import FullScreenVideo from './section-components/FullScreenVedio';
import PenthouseProduct from './section-components/PenthouseProduct';
import Townhouses from './section-components/Townhouses';
import TownhousesGrid from './section-components/TownhousesGrid';
import OurMission from './section-components/OurMission';
import Navbar from './global-components/navbar';
import Footer from './global-components/footer-v2';
import TestimonialV2 from './section-components/testimonial-v2';
import TestimonialV3 from './section-components/testimonial-v3';
import MegaMenuNavbar from './global-components/navbar';

const Home_V1 = () => {
    const dispatch = useDispatch();


    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      const { loading, homePage } = useSelector((state) => state.homePage);
      const { loadingComm, communities } = useSelector(
        (state) => state?.allCommunities
      );
      const memoizedCommunities = useMemo(() => communities, [communities]);
      const { subComm } = useParams();
      useEffect(() => {
        dispatch(getAllPropertiesApi());
        dispatch(getHomePageApi());
        dispatch(getAllCommunitiesApi());
    
        window.scrollTo(0, 0);
      }, [dispatch]);

    
    return <>
    {homePage?.length > 0 &&
      <MetaData title={"Ya Homes Real Estate"} metaKeyword={homePage[0]?.seo_teg} metaDesription={homePage[0]?.seo_des} />

    }

        <Navbar />
       
        <Banner communities={memoizedCommunities} />
        <Service />
        <BestVillas/>
        {/* <Product /> */}
        <Video />
        {/* <ApartmentProduct /> */}
        <FullScreenVideo/>
        {/* <PenthouseProduct/> */}
        {/* <Townhouses/> */}

        {/* <TownhousesGrid/> */}
        <TestimonialV2 />
        <OurMission/>
        <Cta />
       {/* <Agent /> */}
        {/* <Client /> */}
        <LatestNews />
        <Footer />
    </>
}

export default Home_V1

