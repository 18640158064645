import React, { useEffect } from 'react';
import { TEAM_API_URL } from '../../constants/config';
import { useParams, useHistory } from 'react-router-dom';
import { removeSpaceAndJoin } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { getTeamsApi } from '../../actions/teamActions';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Team = () => {
    const { loading, team } = useSelector((state) => state.team);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getTeamsApi());
    }, [dispatch]);
    const history = useHistory();
    let publicUrl = process.env.PUBLIC_URL + '/';

    const handleTeamMemberClick = (name, id) => {
        history.push(`/team/${removeSpaceAndJoin(name)}/${id}`);
    };

    return (
        <div className="agent-area text-center pd-top-118 pd-bottom-90">
            <div className="container">
                <h2 className="section-title">Meet Our Team</h2>
                <div className="row">
                    {team?.map((item, index) => (
                        <div key={item?.id} className="col-lg-4 col-md-6">
                            <div className="single-agent-wrap style-2 text-center">
                                <div className="thumb">
                                <LazyLoadImage
        src={`${TEAM_API_URL}/${item?.path}`}
        alt={`${item?.name}`}
        effect='opacity'
      />                                </div>
                                <div className="details">
                                    <h4 onClick={() => { handleTeamMemberClick(item?.name, item?.id) }}>{"Rahil Hasan"}</h4>
                                    <h6>{item?.designation}</h6>
                                    <ul className="social-area style-2">
                                        <li><a href="#"><i className="fab fa-facebook-f" aria-hidden="true" /></a></li>
                                        <li><a href="#"><i className="fab fa-linkedin-in" aria-hidden="true" /></a></li>
                                        <li><a href="#"><i className="fab fa-instagram" aria-hidden="true" /></a></li>
                                        <li><a href="#"><i className="fab fa-twitter" aria-hidden="true" /></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Team;
