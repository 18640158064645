import React, { useEffect } from "react";
import NavbarV2 from "./navbar-v2";
import { useDispatch, useSelector } from "react-redux";
import { getListWithUsUsApi } from "../../actions/listWithUsActions";
import MetaData from "../../layout/MetaData";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import Footer_v2 from "./footer-v2";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SellWithUs = () => {
  const cardData = [
    {
      title: "Card 1",
      description:
        "A long description for Card 1. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non nulla eget arcu egestas facilisis. Phasellus id bibendum nunc.",
    },
    {
      title: "Card 2",
      description:
        "A long description for Card 2. Curabitur eget dolor eu tellus ullamcorper eleifend. Suspendisse potenti.",
    },
    {
      title: "Card 3",
      description:
        "A long description for Card 3. Fusce auctor mauris sit amet nunc auctor, vel tempor erat egestas. Donec vel bibendum elit.",
    },
    {
      title: "Card 4",
      description:
        "A long description for Card 4. Nullam nec odio eu quam varius tincidunt. Integer id erat id felis consequat facilisis.",
    },
    {
      title: "Card 5",
      description:
        "A long description for Card 5. Duis venenatis libero non arcu dignissim, id vestibulum erat feugiat. Morbi auctor orci et eros tincidunt rhoncus.",
    },
    {
      title: "Card 6",
      description:
        "A long description for Card 6. Phasellus a odio in nisl fringilla hendrerit. In in risus in eros consectetur efficitur.",
    },
  ];
  const { loading, listWithUs } = useSelector((state) => state.listWithUs);
  const dispatch = useDispatch();
  useEffect(() => dispatch(getListWithUsUsApi()), [dispatch]);
  return (
    <>
      {listWithUs?.length > 0 && (
        <MetaData
          title={listWithUs[0]?.seo_titel}
          metaKeyword={listWithUs[0]?.seo_teg}
          metaDesription={listWithUs[0]?.seo_des}
        />
      )}
      <NavbarV2 />
      {/*section 1*/}
      <Container className="mt-120">
        {listWithUs?.map((item, index) => (
          <>
            <Row key={index} className="align-items-center">
              <Col xs={12} md={6}>
                <LazyLoadImage
                  src="https://image.khaleejtimes.com/?uuid=a64d0949-72ad-45b2-a168-6e0ffcd3d0fb&function=cropresize&type=preview&source=false&q=75&crop_w=0.99999&crop_h=0.86985&x=0&y=0&width=1200&height=675"
                  alt={item?.sec1_h}
                  fluid
                  className="mb-3 hover-slide-up"
                  loading="lazy"
                />
              </Col>
              <Col xs={12} md={6}>
                <h2>{item?.sec1_h}</h2>
                <p>{item?.sec1_dsc}</p>
              </Col>
            </Row>

            <Row className="align-items-center mt-120">
              <Col xs={12} md={6}>
                <h2>{item?.sec2_h}</h2>
                <p>{item?.sec2_dsc}</p>
              </Col>
              <Col xs={12} md={6}>
                <LazyLoadImage
                  src="https://axolonerp.com/wp-content/uploads/2023/06/How-the-UAEs-construction-sector-is-getting-back-to-business-with-an-upward-trend-in-the-property-market-scaled.jpg"
                  alt={item?.sec2_h}
                  fluid
                  className="mb-3 hover-slide-up"
                  loading="lazy"
                />
              </Col>
            </Row>
            <h2 className="mt-100">{item?.sec5_h}</h2>

            <Row className="justify-content-center">
              <Col key={index} xs={12} sm={6} md={4} lg={4}>
                <Card className="custom-card">
                  <Card.Body>
                    <Card.Text>{item?.sec5_b1}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col key={index} xs={12} sm={6} md={4} lg={4}>
                <Card className="custom-card">
                  <Card.Body>
                    <Card.Text>{item?.sec5_b2}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col key={index} xs={12} sm={6} md={4} lg={4}>
                <Card className="custom-card">
                  <Card.Body>
                    <Card.Text>{item?.sec5_b3}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row className="justify-content-center mt-60">
              <Col key={index} xs={12} sm={6} md={4} lg={4}>
                <Card className="custom-card">
                  <Card.Body>
                    <Card.Text>{item?.sec5_b4}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col key={index} xs={12} sm={6} md={4} lg={4}>
                <Card className="custom-card">
                  <Card.Body>
                    <Card.Text>{item?.sec5_b5}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col key={index} xs={12} sm={6} md={4} lg={4}>
                <Card className="custom-card">
                  <Card.Body>
                    <Card.Text>{item?.sec5_b6}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row className="align-items-center mt-120">
              <Col xs={12} md={6}>
                <h2>{item?.sec3_h}</h2>
                <p>{item?.sec3_dsc}</p>
              </Col>
              <Col xs={12} md={6}>
                <LazyLoadImage
                  src="https://assets-news.housing.com/news/wp-content/uploads/2020/04/03125400/How-to-calculate-land-value-FB-1200x700-compressed.jpg"
                  alt={item?.sec3_h}
                  fluid
                  className="mb-3 hover-slide-up"
                  loading="lazy"
                />
              </Col>
            </Row>
            <h2 className="mt-100">{item?.sec9_h}</h2>

            <Row className="justify-content-center ">
              <Col xs={12} sm={6} md={4} lg={4}>
                <Card className="custom-card">
                  <Card.Body>
                    <Card.Text>{item?.sec9_b1}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col key={index} xs={12} sm={6} md={4} lg={4}>
                <Card className="custom-card">
                  <Card.Body>
                    <Card.Text>{item?.sec9_b2}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <Card className="custom-card">
                  <Card.Body>
                    <Card.Text>{item?.sec9_b3}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row className="justify-content-center mt-60">
              <Col xs={12} sm={6} md={4} lg={4}>
                <Card className="custom-card">
                  <Card.Body>
                    <Card.Text>{item?.sec9_b4}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col xs={12} sm={6} md={4} lg={4}>
                <Card className="custom-card">
                  <Card.Body>
                    <Card.Text>{item?.sec9_b5}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <Card className="custom-card">
                  <Card.Body>
                    <Card.Text>{item?.sec9_b6}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row className="align-items-center mt-120">
              <Col xs={12} md={6}>
                <h2>{item?.pre_h} </h2>
                <p>{item?.pre_dsc}</p>
              </Col>
              <Col xs={12} md={6}>
                <Image
                  src="https://hockingstuart.com.au/media/fdiflzk0/hs-blog-image-tips-on-preparing-a-property-for-rent.jpg"
                  fluid
                  className="mb-3 hover-slide-up"
                  loading="lazy"
                />
              </Col>
            </Row>

            <h2 className="mt-100">{item?.sec11_h}</h2>

            <Row className="justify-content-center ">
              <Col key={index} xs={12} sm={6} md={4} lg={4}>
                <Card className="custom-card">
                  <Card.Body>
                    <Card.Text>{item?.sec11_b1}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col key={index} xs={12} sm={6} md={4} lg={4}>
                <Card className="custom-card">
                  <Card.Body>
                    <Card.Text>{item?.sec11_b2}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col key={index} xs={12} sm={6} md={4} lg={4}>
                <Card className="custom-card">
                  <Card.Body>
                    <Card.Text>{item?.sec11_b3}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row className="justify-content-center mt-60">
              <Col key={index} xs={12} sm={6} md={4} lg={4}>
                <Card className="custom-card">
                  <Card.Body>
                    <Card.Text>{item?.sec11_b4}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col key={index} xs={12} sm={6} md={4} lg={4}>
                <Card className="custom-card">
                  <Card.Body>
                    <Card.Text>{item?.sec11_b5}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col key={index} xs={12} sm={6} md={4} lg={4}>
                <Card className="custom-card">
                  <Card.Body>
                    <Card.Text>{item?.sec11_b6}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row className="align-items-center mt-120">
              <Col xs={12} md={6}>
                <h2>{item?.sec4_h} </h2>
                <p>{item?.sec4_dsc}</p>
              </Col>
              <Col xs={12} md={6}>
                <LazyLoadImage
                  src="https://realty-cards.com/images/real-estate/folders/RAC-PF-04.png"
                  alt={item?.sec4_h} // Provide an appropriate alt text
                  fluid
                  className="mb-3 hover-slide-up"
                  loading="lazy"
                />
              </Col>
            </Row>

            <h2 className="mt-100">{item?.sec14_h}</h2>
            <Row className="justify-content-center">
              <Col key={index} xs={12} sm={6} md={6} lg={4}>
                <Card className="custom-card">
                  <Card.Body>
                    <Card.Text>{item.sec14_b1}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col key={index} xs={12} sm={6} md={6} lg={4}>
                <Card className="custom-card">
                  <Card.Body>
                    <Card.Text>{item.sec14_b2}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col key={index} xs={12} sm={6} md={6} lg={4}>
                <Card className="custom-card">
                  <Card.Body>
                    <Card.Text>{item.sec14_b3}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row className="justify-content-center mt-60">
              <Col xs={12} sm={6} md={6} lg={4}>
                <Card className="custom-card">
                  <Card.Body>
                    <Card.Text>{item.sec14_b1}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4}>
                <Card className="custom-card">
                  <Card.Body>
                    <Card.Text>{item.sec14_b2}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col key={index} xs={12} sm={6} md={6} lg={4}>
                <Card className="custom-card">
                  <Card.Body>
                    <Card.Text>{item.sec14_b3}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row className="align-items-center mt-120">
              <Col xs={12} md={6}>
                <h2>{item?.sec12_h}</h2>
                <p>{item?.sec12_dsc}</p>
              </Col>
              <Col xs={12} md={6}>
                <LazyLoadImage
                  src="https://www.hubspot.com/hubfs/digital-marketing_1.webp"
                  alt={item?.sec12_h} // Provide an appropriate alt text
                  fluid
                  className="mb-3 hover-slide-up"
                  loading="lazy"
                />
              </Col>
            </Row>
            <Row className="align-items-center mt-120">
              <Col xs={12} md={6}>
                <LazyLoadImage
                  src="https://www.helpguide.org/wp-content/uploads/2023/02/Making-Good-Friends.jpeg"
                  alt={item?.sec13_h} // Provide an appropriate alt text
                  fluid
                  className="mb-3 hover-slide-up"
                  loading="lazy"
                />
              </Col>
              <Col xs={12} md={6}>
                <h2>{item?.sec13_h}</h2>
                <p>{item?.sec13_dsc}</p>
              </Col>
            </Row>
          </>
        ))}
      </Container>
      <Footer_v2 />
    </>
  );
};

export default SellWithUs;
