import React from 'react'
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';

const OffPlanBreadcrum = () => {
  return (
    <Container className='mt-100'>
    <Row>
      <Col lg={12} md={8} sm={10} xs={12}>
        <h2>Villas For Sale In Jumeirah Golf Estates
</h2>
      </Col>
    </Row>
    <Row>
      <Col>
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="#">OffPlan</Breadcrumb.Item>
        </Breadcrumb>
      </Col>
    </Row>
  </Container>  )
}

export default OffPlanBreadcrum