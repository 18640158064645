import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useDispatch, useSelector } from 'react-redux';
import { getPropertiesImagesApi } from '../../actions/propertiesActions';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { PROPERTY_IMAGES_URL } from '../../constants/config';
import Images from './Images';

const ImageCarousel = ({details,name}) => {
    const { loading, propertyImages } = useSelector((state) => state.propertyImages)
const dispatch = useDispatch()

const { id } = useParams()

useEffect(() => {
    dispatch(getPropertiesImagesApi(id))
}, [dispatch])
   
 
  return (
    <>
    {propertyImages?.length>0&&    <Images details={details} name={name} images={propertyImages}/>
}
    </>

    );
};

export default ImageCarousel;
