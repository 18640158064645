import React, { useEffect, useState } from 'react'
import NavbarV2 from '../global-components/navbar-v2'
import CommunityHeader from '../global-components/Property/Community/CommunityHeader'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { capitalizeWords, getExclusivePropertiesInaCommunityUtils, getSubCommunitiesInaCommunityUtils } from '../../utils'
import SubCommunitiesInACommunity from '../global-components/Property/Community/SubCommunitiesInACommunity'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../layout/Loader/Loader'
import PropertyGrid from '../PropertyGrid'
import MetaData from '../../layout/MetaData'

const ExclusiveCommunityPage = () => {
    const { loading:ld,  community } = useSelector((state) => state.community);
    const [propertyTypes, setPropertyTypes] = useState([]);
    const [propertyCategory, setpropertyCategory] = useState([])
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [getSubCommunitiesInaCommunity, setGetSubCommunitiesInaCommunity] = useState([])
    const {property_city,property_type,property_for,property_community}=useParams()
    const dispatch = useDispatch()
    let history = useHistory();
    const orignalCommunity=property_community?.split("-").join(" ")

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const data = await getExclusivePropertiesInaCommunityUtils(property_for,orignalCommunity);

                setpropertyCategory(data);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading2(true);
                const data = await getSubCommunitiesInaCommunityUtils(property_type,property_for,orignalCommunity);
                setGetSubCommunitiesInaCommunity(data);
                setLoading2(false);
            } catch (error) {
                console.error(error);
                setLoading2(false);
            }
        };

        fetchData();
    }, []);
    if (ld) {
        return (
            <Loader />
        )
    }
  return (
    <>
            <MetaData title={` ${capitalizeWords("Properties")} For ${capitalizeWords(property_for)} In ${capitalizeWords(orignalCommunity)}`+`  ` +`-`+` `+` `+ `Ya Homes Real Estate` } metaDesription={`Check Our Verified Listing Of Dubai ${capitalizeWords("Properties")} For ${capitalizeWords(property_for)} In ${capitalizeWords(orignalCommunity)} With World Class Amenities, Amazing Views And Attractive Lifestyle`}/>

    <NavbarV2 />
    <CommunityHeader city={"Dubai"} type={capitalizeWords("Properties")} for={capitalizeWords(property_for)} comm={capitalizeWords(orignalCommunity)} />
    <SubCommunitiesInACommunity community={capitalizeWords(orignalCommunity)} loading={loading2} getSubCommunitiesInaCommunity={getSubCommunitiesInaCommunity}/>
    <PropertyGrid related={true} categoryProperties={propertyCategory}/>

    
    </>
  )
}

export default ExclusiveCommunityPage