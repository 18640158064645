import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { LazyLoadImage } from "react-lazy-load-image-component";

class Service extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showFullText1: false,
      showFullText2: false,
      showFullText3: false,
    };
  }

  toggleReadMore = (section) => {
    this.setState((prevState) => ({
      [`showFullText${section}`]: !prevState[`showFullText${section}`],
    }));
  };

  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    const contents = [
      {
        title: "Sell Property",
        text: "Maximize your property's potential with our seasoned real estate professionals. From accurate property valuation to strategic marketing, we ensure a seamless selling process. Trust us to connect you with qualified buyers and negotiate the best deal for your prized asset.",
      },
      {
        title: "Rent Apartment",
        text: "Turn your property into a lucrative source of income. Our rental experts handle everything from tenant screening to lease agreements, ensuring a hassle-free rental experience. With our support, you can enjoy consistent rental income while we take care of property management and tenant satisfaction.",
      },
      {
        title: "Family House",
        text: "Find the perfect family house that fits your lifestyle. Our selection of family homes offers spacious layouts, modern amenities, and a serene environment, making it an ideal place for your loved ones to create lasting memories. Experience the joy of coming home to a space designed for comfort and togetherness.",
      },
    ];

    return (
      <div className="service-area">
        <div className="container">
          <div className="row justify-content-center">
            {contents.map((content, index) => (
              <div className="col-lg-4 col-md-10" key={index}>
                <div className="single-service-wrap">
                  <div className="thumb">
                    <LazyLoadImage
                      alt="icon"
                      src={publicUrl + `assets/img/service/${index + 1}.png`}
                    />{" "}
                  </div>
                  <div className="single-service-details">
                    <h4>
                      <a href="property-details.html">{content.title}</a>
                    </h4>
                    <p>
                      {this.state[`showFullText${index + 1}`]
                        ? content.text
                        : content.text.slice(0, 150) + "..."}
                    </p>
                    {content.text.length > 150 && (
                      <Link
                        to="#" // Replace with the actual link you want to navigate to
                        onClick={() => this.toggleReadMore(index + 1)}
                        className="read-more-link"
                      >
                        {this.state[`showFullText${index + 1}`]
                          ? "Read Less"
                          : "Read More"}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Service;
