import React, { useEffect } from 'react'
import NavbarV2 from '../global-components/navbar-v2'
import IntroSection from '../blog-components/IntroSection'
import SearchBar from '../blog-components/SearchBar'
import Features from '../blog-components/Features'
import PropertiesGrid from '../section-components/PropertiesGrid'
import { getPropertiesFor } from '../../actions/propertiesActions'
import { useDispatch, useSelector } from 'react-redux'
import {
   useLocation,
    useParams
  } from "react-router-dom";
import MetaData from '../../layout/MetaData'
import { capitalizeFirstLetter } from '../../utils'
import Footer from '../global-components/footer-v2';
const ExclusiveProperties = () => {
    const {loading,propertiesFor}=useSelector((state)=>state.propertiesFor)
  const location = useLocation();
  const pathnameParts = location.pathname.split('/');
  const {property_for,property_city}=useParams()
 
  const dispatch=useDispatch()
const  separatedStrings = property_city.split("-");

  useEffect(()=>{
dispatch(getPropertiesFor(separatedStrings[0]))
  },[dispatch])
  return (
   <>
       <MetaData title={` ${capitalizeFirstLetter("Properties")} For ${capitalizeFirstLetter(separatedStrings[0])} In ${capitalizeFirstLetter(separatedStrings[1])}`+` `+`-`+` `+`Ya Homes Real Estate`} metaDesription={`Check Our Verified Listing Of Dubai ${capitalizeFirstLetter("Properties")} For ${capitalizeFirstLetter(separatedStrings[0])} In Dubai With World Class Amenities, Amazing Views And Attractive Lifestyle`}/>

           <NavbarV2 />
           <IntroSection separatedStrings={separatedStrings}/>
           <SearchBar/>
           <Features/>
           <PropertiesGrid propertiesData={propertiesFor}/>
           <Footer/>

   </>
  )
}

export default ExclusiveProperties