import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const Features = () => {
  return (
    <Container className='feature-section'>
      <Row className='justify-content-between '>
        <Col md={6} className=''>
          <div>
            <h3>Discover Your Dream Home</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Nullam euismod tincidunt ex, in tempus lectus feugiat eu.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Nullam euismod tincidunt ex, in tempus lectus feugiat eu.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Nullam euismod tincidunt ex, in tempus lectus feugiat eu.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Nullam euismod tincidunt ex, in tempus lectus feugiat eu.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Nullam euismod tincidunt ex, in tempus lectus feugiat eu.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Nullam euismod tincidunt ex, in tempus lectus feugiat eu.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Nullam euismod tincidunt ex, in tempus lectus feugiat eu.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Nullam euismod tincidunt ex, in tempus lectus feugiat eu.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Nullam euismod tincidunt ex, in tempus lectus feugiat eu.
              
            </p>
          </div>
        </Col>
        <Col md={6} className='feature-part-2'>
          <Row className='justify-content-center'>
            <Col md={6} sm={6} xs={12} className='mb-3'>
              <Card className='feature-card' style={{ backgroundImage: `url("https://dq5r178u4t83b.cloudfront.net/wp-content/uploads/sites/15/2019/03/17114621/Beach-Villa-swimming-pool.jpg")` }}>
                <Card.Body className='feature-card-body'>
                  <Card.Text className='feature-card-text'>Villa</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} sm={6} xs={12} className='mb-3'>
              <Card className='feature-card' style={{ backgroundImage: `url("https://static.propsearch.ae/dubai-locations/verdana-townhouses_g26IB_xl.jpg")` }}>
                <Card.Body className='feature-card-body'>
                  <Card.Text className='feature-card-text'>Townhouse</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} sm={6} xs={12} className='mb-3'>
              <Card className='feature-card' style={{ backgroundImage: `url("https://assets.cntraveller.in/photos/60b9ed9c13fda2fd38ad7756/master/pass/Intercon%202-987142274-1366x768.jpg")` }}>
                <Card.Body className='feature-card-body'>
                  <Card.Text className='feature-card-text'>Apartment</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} sm={6} xs={12} className='mb-3'>
              <Card className='feature-card' style={{ backgroundImage: `url("https://thepenthouse.co/wp-content/uploads/2022/05/dubai-1.jpg")` }}>
                <Card.Body className='feature-card-body'>
                  <Card.Text className='feature-card-text'>Penthouse</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Features;
