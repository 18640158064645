import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getAllSubCommunitiesApi,
  getAllTypesApi,
  getSubCommunitiesInACommunityApi,
} from "../../actions/communityActions";
import Select, { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MdLocationOn } from "react-icons/md";

import { faMoneyBillAlt } from "@fortawesome/free-solid-svg-icons";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { faBed } from "@fortawesome/free-solid-svg-icons";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
const Banner = ({ communities }) => {
  const { type } = useSelector((state) => state.alltypes);
  const { subCommunities } = useSelector((state) => state?.allSubCommunities);
  let publicUrl = process.env.PUBLIC_URL + "/";
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedCommunity, setSelectedCommunity] = useState(null);
  const [selectedSubCommunity, setSelectedSubCommunity] = useState(null);
  const [selectedType, setselectedType] = useState(null);
  const options = communities?.map((community) => ({
    value: community.id,
    label: community.community_name,
  }));
  const optionsSubComm = subCommunities?.map((community) => ({
    value: community.id,
    label: community.sub_community,
  }));
  const optionTypes = type?.map((community) => ({
    value: community.id,
    label: community.type,
  }));
  const handleSelectTypeChange = (selectedOption) => {
    setselectedType(selectedOption.label);
  };
  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <FontAwesomeIcon
        icon={faMapMarkerAlt}
        style={{
          position: "absolute",
          top: "48%",
          left: "0.6rem",
          bottom: "0.5rem",
          transform: "translateY(-50%)",
          color: "#FCC3006",
        }}
      />
      {children}
    </components.SingleValue>
  );

  const SingleValueSubComm = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <FontAwesomeIcon
        icon={faMapMarkerAlt}
        style={{
          position: "absolute",
          top: "48%",
          left: "0.6rem",
          bottom: "0.5rem",
          transform: "translateY(-50%)",
          color: "#FCC3006",
        }}
      />

      {children}
    </components.SingleValue>
  );
  const SingleValueTypes = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      {props.data && (
        <FontAwesomeIcon
          icon={faHome}
          style={{
            position: "absolute",
            top: "48%",
            left: "0.6rem",
            bottom: "0.5rem",
            transform: "translateY(-50%)",
            color: "#FCC3006",
          }}
        />
      )}
      {children}
    </components.SingleValue>
  );

  const SingleValuePrice = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      {props.data && (
        <FontAwesomeIcon
          icon={faDollarSign}
          style={{
            position: "absolute",
            top: "48%",
            left: "0.6rem",
            bottom: "0.5rem",
            transform: "translateY(-50%)",
            color: "#FCC3006",
          }}
        />
      )}
      {children}
    </components.SingleValue>
  );
  const SingleValueBed = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      {props.data && (
        <FontAwesomeIcon
          icon={faBed}
          style={{
            position: "absolute",
            top: "48%",
            left: "0.6rem",
            bottom: "0.5rem",
            transform: "translateY(-50%)",
            color: "#FCC3006",
          }}
        />
      )}
      {children}
    </components.SingleValue>
  );
  const handleSelectChange = (selectedOption) => {
    setSelectedCommunity(selectedOption.label);
  };
  const handleSelectChangeSubComm = (selectedOption) => {
    setSelectedSubCommunity(selectedOption.label);
  };

  const optionsBed = [
    { value: "1", label: "1" },
    { value: "2", label: "2 " },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5 " },
    { value: "6", label: "6" },
  ];

  const customStyles = {
    // ... your custom styles
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "white",

      boxShadow: state.isFocused ? 0 : 0, // Remove box shadow when focused
      borderColor: state.isFocused ? "transparent" : "#FFF", // Remove border color when focused
      "&:hover": {
        borderColor: "transparent", // Remove border color on hover
      },
      display: "flex",
      justifyContent: "space-between",
      borderRadius: "8px",

      height: "55px", // Set the height
      width: "100%", // Set the width
      position: "relative",
      whiteSpace: "nowrap", // Prevent line break
    }),
    placeholder: (provided, state) => ({
      ...provided,
      position: "absolute",
      top: "50%",
      left: "0px",
      transform: "translateY(-50%)",
      color: state.isDisabled ? "#999" : "#333",
      display: "flex",
      alignItems: "start",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      fontSize: "7px", // Decrease the font size
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none", // Remove the indicator separator
    }),
    singleValue: (provided) => ({
      ...provided,
      paddingLeft: "24px", // Adjust the value as per your spacing preference
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Adjust the value as per your requirement
    }),
  };

  const priceOptions = [
    { value: "0-500000", label: "0-500,000" },
    { value: "500000-1000000", label: "500,000-1,000,000" },
    { value: "1000000-5000000", label: "1,000,000-5,000,000" },
    { value: "5000000-10000000", label: "5,000,000-10,000,000" },
    { value: "above10000000", label: "Above 10,000,000" },
  ];

  const url = (titleName) => {
    return titleName?.split(" ")?.join("-")?.toLowerCase();
  };
  const handlesearch = (e) => {
    e.preventDefault();
    if (
      selectedCommunity == null &&
      selectedSubCommunity == null &&
      selectedType == null
    ) {
      alert.error("No options selected");
    } else {
      if (selectedCommunity && selectedSubCommunity && selectedType) {
        history.push(
          `/dubai/${url(selectedCommunity)}/${url(selectedType)}-for-sale-${url(
            selectedSubCommunity
          )}`
        );
      } else if (selectedCommunity && selectedType) {
        history.push(
          `dubai/${url(selectedType)}-for-sale-dubai-${url(selectedCommunity)}`
        );
      } else if (selectedType) {
        history.push(`/${url(selectedType)}-for-sale-dubai`);
      }
    }
  };
  const handlesearch2 = (e) => {
    e.preventDefault();
    if (
      selectedCommunity == null &&
      selectedSubCommunity == null &&
      selectedType == null
    ) {
      alert.error("No options selected");
    } else {
      if (selectedCommunity && selectedSubCommunity && selectedType) {
        history.push(
          `/dubai/${url(selectedCommunity)}/${url(selectedType)}-for-rent-${url(
            selectedSubCommunity
          )}`
        );
      } else if (selectedCommunity && selectedType) {
        history.push(
          `dubai/${url(selectedType)}-for-rent-dubai-${url(selectedCommunity)}`
        );
      } else if (selectedType) {
        history.push(`/${url(selectedType)}-for-rent-dubai`);
      }
    }
  };

  const handlesearch3 = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    dispatch(getAllSubCommunitiesApi(selectedCommunity));
    dispatch(getAllTypesApi());
    if (selectedCommunity) {
      dispatch(getSubCommunitiesInACommunityApi(selectedCommunity?.label));
    }
  }, [dispatch, selectedCommunity]);

  return (
    <div
      className="banner-area banner-area-1 banner-area-bg"
      style={{
        background:
          "url(https://www.hhoteldubai.com/wp-content/uploads/2017/08/Royal-Suite_Terrace.jpg)",
      }}
    >
      <div className="container">
        <div className="banner-area-inner">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="banner-inner text-center">
                <p>Discover Your Dream Home in Dubai's Premier Real Estate</p>
                <div className="line" />
                <h2>The Best Way To Find Your Perfect Home</h2>
              </div>
            </div>
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
