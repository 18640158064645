import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class TestimonialV3 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="testimonial-area pd-top-118 pd-bottom-120 bg-overlay" style={{background: 'url('+publicUrl+'/assets/img/bg/2.png)'}}>
			  <div className="bg-overlay-wrap">
			    <div className="container">
			      <div className="section-title  text-center">
			        <h6>Our Testomonial </h6>
			        <h2>What Client Say</h2>
			        <p> Seamless Collaboration,{" "}Personalized Attention{" "},Relaible Support </p>
			      </div>
			      <div className="row justify-content-center">
			        <div className="col-lg-10">
			          <div className="testimonial-slider-1 owl-carousel">
			            <div className="item">
			              <div className="single-testimonial-inner pl-lg-5 pr-lg-5 text-center">
			                <div className="thumb">
			                  <img src={"https://solverwp.com/demo/react/mingrand/assets/img/testimonial/4.png"} alt="img" />
			                </div>
			                <div className="details">
			                  <p>"I was amazed by the exceptional service provided by this company. They went above and beyond to meet my needs and deliver results that exceeded my expectations" </p>
			                  <h6 className="name">Sarif Jaya Miprut</h6>
			                  <span className="designation">Profile Manager</span>
			                </div>
			              </div>
			            </div>
			            <div className="item">
			              <div className="single-testimonial-inner pl-lg-5 pr-lg-5 text-center">
			                <div className="thumb">
			                  <img src={"https://solverwp.com/demo/react/mingrand/assets/img/testimonial/1.png"} alt="img" />
			                </div>
			                <div className="details">
			                  <p>Working with this team has been a true game-changer for me. Their expertise and dedication have transformed my business and brought it to new heights </p>
			                  <h6 className="name">Sarif Jaya Miprut</h6>
			                  <span className="designation">Profile Manager</span>
			                </div>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>

        }
}

export default TestimonialV3