import React, { useState } from 'react';

const ServiceV3 = ({ aboutUsContent }) => {
    const publicUrl = process.env.PUBLIC_URL;

    const serviceItems = [
        {
            backgroundImage: `url(${publicUrl}/assets/img/service/01.png)`,
            icon: `${publicUrl}/assets/img/service/1.png`,
            title: 'Our Mission',
            description: 'The space you live in and the things you surround yourself with will directly influence your life. Thus, we are committed to provide our clients with a diverse and well thought portfolio of properties and investment opportunities in prime locations that complement their lifestyles',
        },
        {
            backgroundImage: `url(${publicUrl}/assets/img/service/02.png)`,
            icon: `${publicUrl}/assets/img/service/2.png`,
            title: 'Our Passion',
            description: 'Our passion lies in our desire to achieve excellence in what we do by not only giving clients a desirable space to live, but also allowing them to experience the culture and vibrancy of Dubai through its residential properties.',
        },
        {
            backgroundImage: `url(${publicUrl}/assets/img/service/03.png)`,
            icon: `${publicUrl}/assets/img/service/3.png`,
            title: 'Our Values',
            description: "In Ya Homes Real Estate, we believe in ethics, honesty and professionalism. We believe that by showing our clients the utmost respect and giving them a great deal of transparency, they will better understand our company ethos. We also believe that if you show your clients the best product, services, and information you can get, they'll greatly appreciate your work. Therefore, we will always strive to provide these things to all our clients so that they can have an elevated experience with us.",
        },
    ];

    const Description = ({ description }) => {
        const [expanded, setExpanded] = useState(false);

        const toggleExpansion = () => {
            setExpanded(!expanded);
        };

        return (
            <div>
                <p>
                    {expanded ? description : description.substring(0, 200)}
                    {description.length > 200 && (
                        <span onClick={toggleExpansion} className="read-more-link blue-color">
                            {expanded ? 'Read Less' : 'Read More'}
                        </span>
                    )}
                </p>
                {expanded && description.length > 200 && (
                    <span onClick={toggleExpansion} className="read-more-link blue-color">
                        Read Less
                    </span>
                )}
            </div>
        );
    };

    return (
        <div className="service-area mg-top--50">
            <div className="container">
                <div className="row justify-content-center">
                    {serviceItems.map((item, index) => (
                        <div className="col-lg-4 col-md-10" key={index}>
                            <div className="single-service-wrap style-2 small-padding" style={{ backgroundImage: item.backgroundImage }}>
                                <div className="thumb">
                                    <img className='serviceImages' src={item.icon} alt="icon" />
                                </div>
                                <div className="single-service-details">
                                    <h4><a href="property-details.html">{item.title}</a></h4>
                                    <Description description={item.description} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ServiceV3;
