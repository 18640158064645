import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Pagination } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBed,
  faBath,
  faRulerCombined,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsappSquare } from "@fortawesome/free-brands-svg-icons";
import { TEAM_API_URL } from "../../constants/config";
import { url } from "../../utils";
import { Link } from "react-router-dom";

const PropertiesGrid = ({ propertiesData }) => {
  const itemsPerPage = 8; // Number of properties per page
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastProperty = currentPage * itemsPerPage;
  const indexOfFirstProperty = indexOfLastProperty - itemsPerPage;
  const currentProperties = propertiesData.slice(
    indexOfFirstProperty,
    indexOfLastProperty
  );

  const totalPages = Math.ceil(propertiesData.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Container className="property-grid-section">
      <Row>
        {currentProperties.map((property) => (
          <Col md={4} sm={6} xs={12} key={property.id}>
            <Card className="property-card">
              <div className="property-image">
                <Link
                  to={
                    property?.property_level !== ""
                      ? `/properties/${url(property?.property_community)}/${url(
                          property?.property_sub_community
                        )}/properties-for-${url(property?.property_for)}-${url(
                          property?.property_level
                        )}/th${property?.id}`
                      : `/properties/${url(
                          property?.property_community
                        )}/properties-for-${url(property?.property_for)}-${url(
                          property?.property_sub_community
                        )}/th${property?.id}`
                  }
                >
                  <Card.Img variant="top" src={property.property_imges} />
                  <div className="overlay">
                    <Button variant="dark">View Details</Button>
                  </div>
                </Link>
              </div>
              <Card.Body>
                <Card.Title>{property.property_name}</Card.Title>
                <Card.Text>Price: {property.property_price}</Card.Text>
                <Card.Text>Reference: {property.property_ref_no}</Card.Text>
                <Row className="property-icons">
                  <Col xs={4}>
                    <div className="icon-container">
                      <FontAwesomeIcon icon={faBed} />
                      <span>{property.property_no_of_bedroom}</span>
                    </div>
                  </Col>
                  <Col xs={4}>
                    <div className="icon-container">
                      <FontAwesomeIcon icon={faBath} />
                      <span>{property.property_no_of_bathroom}</span>
                    </div>
                  </Col>
                  <Col xs={4}>
                    <div className="icon-container">
                      <FontAwesomeIcon icon={faRulerCombined} />
                      <span>{property.property_sq_ft}</span>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="property-footer">
                <img
                  src={`${TEAM_API_URL}/${property.path}`}
                  alt="Agent"
                  className="agent-image"
                />
                <div className="agent-contact">
                  <Button variant="primary">
                    <FontAwesomeIcon icon={faPhone} /> Call
                  </Button>
                  <Button variant="success">
                    <FontAwesomeIcon icon={faWhatsappSquare} /> WhatsApp
                  </Button>
                </div>
              </Card.Footer>
            </Card>
          </Col>
        ))}
      </Row>
      {totalPages > 1 && (
        <Pagination className="mt-4">
          {[...Array(totalPages)].map((_, index) => (
            <Pagination.Item
              key={index}
              active={index + 1 === currentPage}
              onClick={() => paginate(index + 1)}
              className="page-item"
            >
              {index + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      )}
    </Container>
  );
};

export default PropertiesGrid;
