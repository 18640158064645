import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const AttractivePlans = () => {
  return (
    <div className="attractive-plans">
      <Container>
        <h2 className="text-center mb-4">Attractive Plans</h2>
        <Row className="justify-content-center">
          <Col md={6} lg={4} className="mb-4">
            <Card className="attractive-card">
              <Card.Body>
                <h4 className="text-center">Booking</h4>
                <p className="percentage-text">20%</p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={4} className="mb-4">
            <Card className="attractive-card">
              <Card.Body>
                <h4 className="text-center">Handover</h4>
                <p className="percentage-text">80%</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AttractivePlans;
