import React from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { ABOUT_IMAGE_URL } from "../../constants/config";
import { Container, Row, Col } from 'react-bootstrap';
import { LazyLoadImage } from "react-lazy-load-image-component";

const About = ({ aboutUsContent }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <Container className="mt-100">
      {
        aboutUsContent?.map((about,index)=>(
          <Row>
          <Col md={6}>
            {/* Image */}
            <LazyLoadImage
        src="https://www.astarealty.com/wp-content/uploads/2018/09/asta-about-us.jpeg"
        alt="Sample Image"
        className="img-fluid hover-zoom"
      />
          </Col>
          <Col md={6}>
            {/* Title */}
            <h2  dangerouslySetInnerHTML={{ __html: about?.about_hed }} />
            {/* Description */}
            <p dangerouslySetInnerHTML={{ __html: about?.about_dse }} />
  
          </Col>
        </Row>
        ))
      }
   
    </Container>
  );
};

export default About;
