import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOffPlanCategoryApi } from '../../../actions/offPlanActions'
import Loader from '../../../layout/Loader/Loader'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { url } from '../../../utils'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const OffPlanCategories = () => {
    const items = [
        {
          imageSrc: 'https://boulevardviews.com/wp-content/uploads/2022/04/WEB-01.jpg',
          heading: 'Item 1',
        },
        {
            imageSrc: 'https://boulevardviews.com/wp-content/uploads/2022/04/WEB-01.jpg',
            heading: 'Item 2',
        },
        {
            imageSrc: 'https://boulevardviews.com/wp-content/uploads/2022/04/WEB-01.jpg',
            heading: 'Item 3',
        },
        {
            imageSrc: 'https://boulevardviews.com/wp-content/uploads/2022/04/WEB-01.jpg',
            heading: 'Item 4',
        },
        {
            imageSrc: 'https://boulevardviews.com/wp-content/uploads/2022/04/WEB-01.jpg',
            heading: 'Item 1',
          },
          {
              imageSrc: 'https://boulevardviews.com/wp-content/uploads/2022/04/WEB-01.jpg',
              heading: 'Item 2',
          },
          {
              imageSrc: 'https://boulevardviews.com/wp-content/uploads/2022/04/WEB-01.jpg',
              heading: 'Item 3',
          },
          {
              imageSrc: 'https://boulevardviews.com/wp-content/uploads/2022/04/WEB-01.jpg',
              heading: 'Item 4',
          },
      ];
    
    const { offPlanCategory, loading } = useSelector((state)=>state.offPlanCategory)
	const dispatch = useDispatch()

	useEffect(() => {

		dispatch(getOffPlanCategoryApi())

	}, [dispatch])
	if(loading){
		return <Loader/>
	}
  return (
    <Container>
    <h2 className="text-left">Off Plan Categories</h2>
    <Row>
      {offPlanCategory?.map((item, index) => (
        <Col key={index} lg={3} md={4} sm={6} xs={12}>
          <Card className="mb-4 image-card">
          <Link to={`/offplan-projects-in-dubai/${url(item?.heading1)}`}>
          <div className="image-container">
            <Card.Img loading='lazy' variant="top" src={"https://boulevardviews.com/wp-content/uploads/2022/04/WEB-01.jpg"} alt={item.heading1} />

              <div className="overlay">
                <div className="overlay-text">{item.heading1}</div>
              </div>
            </div>
          </Link>

           
          </Card>
        </Col>
      ))}
    </Row>
  </Container>
  
  
  )
}

export default OffPlanCategories