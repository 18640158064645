import { GET_COMMUNITY_GUIDELINES_FAILURE, GET_COMMUNITY_GUIDELINES__SUCCESS, GET_COMMUNITY_GUIDELINES__REQUEST, GET_COMMUNITY_CATEGORY__REQUEST, GET_COMMUNITY_CATEGORY__SUCCESS, GET_COMMUNITY_CATEGORY_FAILURE, GET_ALL_COMMUNITY_REQUEST, GET_ALL_COMMUNITY_SUCCESS, GET_ALL_COMMUNITY_FAILURE, GET_ALL_SUB_IN_COMMUNITY_REQUEST, GET_ALL_SUB_IN_COMMUNITY_SUCCESS, GET_ALL_SUB_INCOMMUNITY_FAILURE, GET_ALL_SUB_IN_COMMUNITY_FAILURE, GET_ALL_TYPE_REQUEST, GET_ALL_TYPE_SUCCESS, GET_ALL_TYPE_FAILURE } from "../constants/communityConstants";


export const communityReducer = (state={community:[]}, action) =>{

switch(action.type){
    case GET_COMMUNITY_GUIDELINES__REQUEST:
        return{
            loading: true,
            community:[],
        }

        case GET_COMMUNITY_GUIDELINES__SUCCESS:
            return{
                loading: false,
                community: action.payload,
            }
        case GET_COMMUNITY_GUIDELINES_FAILURE:{
            return{
                loading: true,
                error: action.payload,
            }
        }
        default:
            return state;
}

}



export const communityCategoryReducer = (state={communityCategory:[]}, action) =>{

    switch(action.type){
        case GET_COMMUNITY_CATEGORY__REQUEST:
            return{
                loading: true,
                communityCategory:[],
            }
    
            case GET_COMMUNITY_CATEGORY__SUCCESS:
                return{
                    loading: false,
                    communityCategory: action.payload,
                }
            case GET_COMMUNITY_CATEGORY_FAILURE:{
                return{
                    loading: true,
                    error: action.payload,
                }
            }
            default:
                return state;
    }
    
    }



    export const getAllCommunitiesReducer = (state={communities:[]}, action) =>{

        switch(action.type){
            case GET_ALL_COMMUNITY_REQUEST:
                return{
                    loading: true,
                    communities:[],
                }
        
                case GET_ALL_COMMUNITY_SUCCESS:
                    return{
                        loading: false,
                        communities: action.payload,
                    }
                case GET_ALL_COMMUNITY_FAILURE:{
                    return{
                        loading: true,
                        error: action.payload,
                    }
                }
                default:
                    return state;
        }
        
        }

        export const getAllSubCommunitiesReducer = (state={subCommunities:[]}, action) =>{

            switch(action.type){
                case GET_ALL_SUB_IN_COMMUNITY_REQUEST:
                    return{
                        loading: true,
                        subCommunities:[],
                    }
            
                    case GET_ALL_SUB_IN_COMMUNITY_SUCCESS:
                        return{
                            loading: false,
                            subCommunities: action.payload,
                        }
                    case GET_ALL_SUB_IN_COMMUNITY_FAILURE:{
                        return{
                            loading: true,
                            error: action.payload,
                        }
                    }
                    default:
                        return state;
            }
            
            }




            export const typeReducer = (state={type:[]}, action) =>{

                switch(action.type){
                    case GET_ALL_TYPE_REQUEST:
                        return{
                            loading: true,
                            type:[],
                        }
                
                        case GET_ALL_TYPE_SUCCESS:
                            return{
                                loading: false,
                                type: action.payload,
                            }
                        case GET_ALL_TYPE_FAILURE:{
                            return{
                                loading: true,
                                error: action.payload,
                            }
                        }
                        default:
                            return state;
                }
                
                }