import React, { useEffect } from "react";
import NavbarV2 from "../navbar-v2";
import { useDispatch, useSelector } from "react-redux";
import { OffPlanDetailApi } from "../../../actions/offPlanActions";
import { replaceHyWithSpacesToUppercase } from "../../../utils";
import AboutOffPlan from "./AboutOffPlan";
import Loader from "../../../layout/Loader/Loader";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Upfront from "./Upfront";
import { FeaturesSection } from "./FeatureSection";
import AttractivePlans from "./AttractivePlans";
import ArchitectureMarvel from "./ArchitectureMarvel";
import { TravelTimeSection } from "./TravelTimeSection";
import FloorPlanSection from "./FloorPlanSection";
import OffplanPropertiesSection from "./OffPlanCarousel";
import Product from "../../section-components/product";
import Footer_v2 from "../footer-v2";

const OffPlan = () => {
  const { heading1 } = useParams();

  const dispatch = useDispatch();
  const { loading, offPlanDetails } = useSelector(
    (state) => state.offPlanDetails
  );

  useEffect(() => {
    dispatch(OffPlanDetailApi(replaceHyWithSpacesToUppercase(heading1)));
  }, [dispatch, heading1]);
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <NavbarV2 />
      <AboutOffPlan offPlanDetails={offPlanDetails} />
      <Upfront offPlanDetails={offPlanDetails} />
      <FeaturesSection/>
      <AttractivePlans/>
      <ArchitectureMarvel/>
      <TravelTimeSection/>
      <FloorPlanSection/>
      <Product />
      <Footer_v2/>
    </>
  );
};

export default OffPlan;
