import React, { useState } from "react";
import { Container, Row, Col, Tab, Tabs, Button } from "react-bootstrap";

const FloorPlanSection = () => {
  const [key, setKey] = useState("1BR"); // Initial tab key

  const floorPlans = [
    {
      type: "1BR",
      title: "1 Bedroom Apartment",
      description: "A cozy 1-bedroom apartment for your comfort.",
      totalArea: "800 sqft",
      bedroom: 1,
      bathroom: 1,
      balcony: "Yes",
      petsAllowed: "No",
      image:"https://images.edrawmax.com/examples/apartment-floor-plan/example4.png"
    },
    {
        type: "2BR",
        title: "1 Bedroom Apartment",
        description: "A cozy 1-bedroom apartment for your comfort.",
        totalArea: "800 sqft",
        bedroom: 1,
        bathroom: 1,
        balcony: "Yes",
        petsAllowed: "No",
        image:"https://images.edrawmax.com/examples/apartment-floor-plan/example4.png"

      },
      {
        type: "3BR",
        title: "1 Bedroom Apartment",
        description: "A cozy 1-bedroom apartment for your comfort.",
        totalArea: "800 sqft",
        bedroom: 1,
        bathroom: 1,
        balcony: "Yes",
        petsAllowed: "No",
        image:"https://images.edrawmax.com/examples/apartment-floor-plan/example4.png"

      },
    // Add similar objects for 2 BR and 3 BR floor plans
  ];

  const handleTabSelect = (selectedKey) => {
    setKey(selectedKey);
  };

  return (
    <Container className="floor-plan-section mt-4">
      <Tabs
        id="floor-plan-tabs"
        activeKey={key}
        onSelect={handleTabSelect}
        className="mb-4"
      >
        {floorPlans.map((plan) => (
          <Tab key={plan.type} eventKey={plan.type} title={plan.type}>
            <Row>
              {/* Left Part - Floor Plan Details */}
              <Col md={6}>
                <div className="floor-plan-details">
                  <h3>{plan.title}</h3>
                  <p>{plan.description}</p>
                  <p>
                    <strong>Total Area:</strong> {plan.totalArea}
                  </p>
                  <p>
                    <strong>Bedrooms:</strong> {plan.bedroom}
                  </p>
                  <p>
                    <strong>Bathrooms:</strong> {plan.bathroom}
                  </p>
                  <p>
                    <strong>Balcony:</strong> {plan.balcony}
                  </p>
                  <p>
                    <strong>Pets Allowed:</strong> {plan.petsAllowed}
                  </p>
                  <div className="buttonContainer">
                  <Button variant="primary">Get All Floor Plans</Button>{" "}
                  <Button variant="secondary">Download Brochure</Button>
                  </div>
             
                </div>
              </Col>
              {/* Right Part - Floor Plan Image */}
              <Col md={6}>
                {/* Replace with your floor plan image */}
                <img
                src={plan?.image}
                  alt={`${plan.type} Floor Plan`}
                  className="img-fluid"
                />
              </Col>
            </Row>
          </Tab>
          
        ))}
      </Tabs>
    </Container>
  );
};

export default FloorPlanSection;
