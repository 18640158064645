export const GET_ALL_PROPERTIES_REQUEST="GET_ALL_PROPERTIES_REQUEST"
export const GET_ALL_PROPERTIES_SUCCESS="GET_ALL_PROPERTIES_SUCCESS"
export const GET_ALL_PROPERTIES_FAILURE="GET_ALL_PROPERTIES_FAILURE"

export const GET_PROPERTY_DETAILS_REQUEST="GET_PROPERTY_DETAILS_REQUEST"
export const GET_PROPERTY_DETAILS_SUCCESS="GET_PROPERTY_DETAILS_SUCCESS"
export const GET_PROPERTY_DETAILS_FAILURE="GET_PROPERTY_DETAILS_FAILURE"


export const GET_PROPERTY_IMAGES_REQUEST="GET_PROPERTY_IMAGES_REQUEST"
export const GET_PROPERTY_IMAGES_SUCCESS="GET_PROPERTY_IMAGES_SUCCESS"
export const GET_PROPERTY_IMAGES_FAILURE="GET_PROPERTY_IMAGES_FAILURE"



export const GET_PROPERTY_AMENITIES_REQUEST="GET_PROPERTY_AMENITIES_REQUEST"
export const GET_PROPERTY_AMENITIES_SUCCESS="GET_PROPERTY_AMENITIES_SUCCESS"
export const GET_PROPERTY_AMENITIES_FAILURE="GET_PROPERTY_AMENITIES_FAILURE"


export const GET_PROPERTY_NEAR_BY_REQUEST="GET_PROPERTY_NEAR_BY_REQUEST"
export const GET_PROPERTY_NEAR_BY_SUCCESS="GET_PROPERTY_NEAR_BY_SUCCESS"
export const GET_PROPERTY_NEAR_BY_FAILURE="GET_PROPERTY_NEAR_BY_FAILURE"



export const GET_PROPERTY_LEVEL_REQUEST="GET_PROPERTY_LEVEL_REQUEST"
export const GET_PROPERTY_LEVEL_SUCCESS="GET_PROPERTY_LEVEL_SUCCESS"
export const GET_PROPERTY_LEVEL_FAILURE="GET_PROPERTY_LEVEL_FAILURE"


export const GET_PROPERTY_SUB_COMMUNIY_REQUEST="GET_PROPERTY_SUB_COMMUNIY_REQUEST"
export const GET_PROPERTY_SUB_COMMUNIY_SUCCESS="GET_PROPERTY_SUB_COMMUNIY_REQUEST"
export const GET_PROPERTY_SUB_COMMUNIY_FAILURE="GET_PROPERTY_SUB_COMMUNIY_REQUEST"



export const GET_PROPERTY_IMAGE_REQUEST="GET_PROPERTY_IMAGE_REQUEST"
export const GET_PROPERTY_IMAGE_SUCCESS="GET_PROPERTY_IMAGES_SUCCESS"
export const GET_PROPERTY_IMAGE_FAILURE="GET_PROPERTY_IMAGE_FAILURE"



export const GET_PROPERTY_COMMUNITIES_REQUEST="GET_PROPERTY_COMMUNITIES_REQUEST"
export const GET_PROPERTY_COMMUNITIES_SUCCESS="GET_PROPERTY_COMMUNITIES_SUCCESS"
export const GET_PROPERTY_COMMUNITIES_FAILURE="GET_PROPERTY_COMMUNITIES_FAILURE"


export const GET_PROPERTIES_FOR__REQUEST="GET_PROPERTIES_FOR_REQUEST"
export const GET_PROPERTIES_FOR__SUCCESS="GET_PROPERTIES_FOR_SUCCESS"
export const GET_PROPERTIES_FOR__FAILURE="GET_PROPERTIES_FOR_FAILURE"


export const GET_RELATED_PROPERTIES_REQUEST="GET_RELATED_PROPERTIES_REQUEST"
export const GET_RELATED_PROPERTIES_SUCCESS="GET_RELATED_PROPERTIES_SUCCESS"
export const GET_RELATED_PROPERTIES_FAILURE="GET_RELATED_PROPERTIES_FAILURE"