import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

const OurStory = ({ storyItems }) => {
    return (
        <section className="our-story-timeline">
            <Container>
                <h2 className="text-center mb-4">Our Story</h2>
                <div className="timeline">
                    <Row>
                        <div className="timeline-line"></div>
                        {storyItems.map((item, index) => (
                            <Col lg={3} sm={12} key={index}>
                                <div className="timeline-item">
                                    <div className="timeline-content">
                                        <LazyLoadImage
                                            src={item.imageSrc}
                                            alt={item.altText}
                                            effect="opacity"
                                            className="img-fluid storyImages"
                                        />
                                        <h4>{item.year}</h4>
                                        <p>{item.event}</p>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            </Container>
        </section>
    );
};

export default OurStory;
