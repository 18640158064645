import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import { getTeamDetailsApi } from '../../actions/teamActions';
import { useDispatch, useSelector } from 'react-redux';
import { TEAM_API_URL } from '../../constants/config';
const AgentDetails = ({agentId}) => {
    const { teamDetails } = useSelector((state) => state?.teamDetails);
    const [showFullDescription, setShowFullDescription] = useState(false);
    const MAX_WORDS = 60;

    const words = teamDetails?.description?.split(' ');
  
    const displayDescription =
      !showFullDescription && words?.length > MAX_WORDS
        ? words.slice(0, MAX_WORDS).join(' ') + '...'
        : teamDetails?.description;
  
    const handleToggleDescription = () => {
      setShowFullDescription(!showFullDescription);
    };
    const dispatch=useDispatch()
    useEffect(() => {
      dispatch(getTeamDetailsApi(parseInt(agentId)));
    }, [dispatch]);
    let publicUrl="/"
  return (
    <div className="widget widget-author text-center">
    <h4 className="widget-title">About Me</h4>
    <div className="thumb">
      <img src={"https://solverwp.com/demo/react/mingrand/assets/img/agent/1.png"} alt="img" />
    </div>
    <div className="details">
      <h5>Sandara Mrikon</h5>
      <p>
        Lorem ipsum dolor amet, Lore ipsum dolor sit amet,
        consectetur et eiLorem ipsum dolor sit amet
      </p>
      <ul>
        <li>
          <a href="#">
            <i className="fab fa-facebook-f" aria-hidden="true" />
          </a>
        </li>
        <li>
          <a href="#">
            <i
              className="fab fa-linkedin-in"
              aria-hidden="true"
            />
          </a>
        </li>
        <li>
          <a href="#">
            <i className="fab fa-instagram" aria-hidden="true" />
          </a>
        </li>
        <li>
          <a href="#">
            <i className="fab fa-twitter" aria-hidden="true" />
          </a>
        </li>
      </ul>
    </div>
  </div>
    )
}

export default AgentDetails