import React, { useEffect, useState } from 'react'
import MetaData from '../../../../layout/MetaData'
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { capitalizeFirstLetter, capitalizeWords, getPropertiesTypeUtils, replaceHyphensAndCapitalize, replaceHyphensWithSpaces } from '../../../../utils'
import TypeBreadcrum from './TypeBreadcrum'
import PropertyGrid from '../../../PropertyGrid'
import TypePropertyContent from './TypePropertyContent'
import Footer_v2 from '../../footer-v2'
import Navbar from '../../../global-components/navbar-v2'
const TypePropertyPage = () => {
  const { property_type, property_for, property_city } = useParams()
  const location = useLocation();
  const pathnameParts = location.pathname.split('/');
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
        try {
            setLoading(true);
            const data = await getPropertiesTypeUtils(replaceHyphensWithSpaces(property_type), property_for, property_city);
           
            setPropertyTypes(data);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    fetchData();
}, []);

  return (
    <>
      <Navbar />
      <MetaData title={` ${capitalizeFirstLetter(property_type)} For  ${capitalizeFirstLetter(property_for)} In ${replaceHyphensAndCapitalize(property_city)}`+` `+`-`+` `+`Ya Homes Real Estate`} metaDesription={`Check Our Verified Listing Of Dubai ${capitalizeFirstLetter(property_type)} For ${capitalizeFirstLetter(property_for)} In Dubai With World Class Amenities, Amazing Views And Attractive Lifestyle`}/>
    
    <TypeBreadcrum property_type={capitalizeWords(property_type)} property_city={capitalizeWords(property_city)} property_for={capitalizeWords(property_for)}/>
   
   
    <TypePropertyContent/>
   <PropertyGrid related={true} categoryProperties={propertyTypes}/>
   <Footer_v2/>
    </>
  )
}

export default TypePropertyPage