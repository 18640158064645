import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { url } from '../../../utils';
import { Container } from 'react-bootstrap';

const PropertyHeaderDetailsWithoutLevel = ({details}) => {
  return (
    <Container>
  <Row>
      <Col lg={12}>
        <Breadcrumb className="sexy-breadcrumb">
          <Breadcrumb.Item href="/">{details?.property_city}</Breadcrumb.Item>
          <Breadcrumb.Item href={`/${url(
                            details?.property_type
                          )}-for-${url(
                            details?.property_for
                          )}-${url(details?.property_city)}`}>{details?.property_type}</Breadcrumb.Item>
          <Breadcrumb.Item  href={`/${url(
                            details?.property_city
                          )}/${url(
                            details?.property_type
                          )}-for-${url(
                            details?.property_for
                          )}-${url(
                            details?.property_community
                          )}`}>{details?.property_community}</Breadcrumb.Item>
          <Breadcrumb.Item href={`/${url(
                            details?.property_city
                          )}/${url(
                            details?.property_community
                          )}/${url(
                            details?.property_type
                          )}-for-${url(
                            details?.property_for
                          )}-${url(
                            details?.property_sub_community
                          )}`}>{details?.property_sub_community}</Breadcrumb.Item>
          <Breadcrumb.Item active>{"TH"}{details?.id}</Breadcrumb.Item>


        </Breadcrumb>
      </Col>
    </Row>
    </Container>
  
  );
};

export default PropertyHeaderDetailsWithoutLevel;
