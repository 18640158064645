import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const FullScreenVideo = () => {
  const videoUrl = "https://www.youtube.com/embed/Wimkqo8gDZ0";

  return (
    <Container className="mt-100">
      <Row>
        <Col md={6}>
          <div className="video-thumbnail">
            <a href={videoUrl} target="_blank" rel="noopener noreferrer">
              <img
                src="https://t4.ftcdn.net/jpg/06/26/61/99/360_F_626619971_Y8etYMdy9j2u0iKpqDRv94nFzoJpyWsF.jpg"
                alt="Video Thumbnail"
              />
              <div className="play-button">
                <i className="fas fa-play"></i>
              </div>
            </a>
          </div>
        </Col>
        <Col md={6}>
          <h2>Best Penthouses In Dubai</h2>
          <p>
            Discover the epitome of luxury living with our handpicked selection
            of the finest penthouses in Dubai. Elevate your lifestyle to new
            heights as you explore these exclusive, meticulously designed
            properties boasting breathtaking views, opulent amenities, and
            unparalleled elegance. Whether you seek a tranquil oasis in the
            heart of the city or a sky-high retreat, our curated list showcases
            the best penthouses Dubai has to offer. Experience the pinnacle of
            urban sophistication and indulge in the lavish comforts of these
            exceptional penthouse residences, where luxury knows no bounds.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default FullScreenVideo;
