import { GET_RELATED_PROPERTIES_FAILURE, GET_RELATED_PROPERTIES_REQUEST, GET_RELATED_PROPERTIES_SUCCESS } from "../constants/propertyConstants";

export const relatedPropertiesReducer = (state ={ relatedProperties: [] },action)=>{
    switch(action.type){
         case GET_RELATED_PROPERTIES_REQUEST:
            return{
                loading: true,
                relatedProperties: [],

            }
        case GET_RELATED_PROPERTIES_SUCCESS:
            return{
                            loading: false,
                            relatedProperties: action.payload
                        }

        case GET_RELATED_PROPERTIES_FAILURE: {
                            return {
                                loading: false,
                                error: action.payload,
                            }
                        }
        default:
            return state;
        }
 }