import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export const FeaturesSection = () => {
    const features = [
      { icon: "fa-camera", title: "24/7 CCTV" },
      { icon: "fa-home", title: "Smart Homes" },
      { icon: "fa-swimming-pool", title: "Infinity Pools" },
      { icon: "fa-couch", title: "Furnished Residences" },
    ];
  
    return (
        <Container className="mt-100">
        <Row>
          {features.map((feature, index) => (
            <Col xs={12} sm={6} md={3} key={index} className="mb-4">
              <div className="text-center feature-item">
                <i className={`fas ${feature.icon} fa-3x mb-3 icon`} />
                <h4>{feature.title}</h4>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    )
  };