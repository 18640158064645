import React, { useEffect, useState } from "react";
import {
    capitalizeWords,
  getExclusivePropertiesInaSubCommunityUtils,
  getSubCommunitiesPropertiesUtils,
  replaceHyphensAndCapitalize,
} from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../../layout/Loader/Loader";
import NavbarV2 from "../global-components/navbar-v2";
import SubCommunityHeader from "../global-components/Property/SubCommunity/SubCommunityHeader";
import LevelsInASubCommunity from "../global-components/Property/SubCommunity/LevelsInASubCommunity";
import PropertyGrid from "../PropertyGrid";
import MetaData from "../../layout/MetaData";

const ExclusivePropertiesSubCommunity = () => {
  const [loading, setLoading] = useState(false);

  const [loading2, setLoading2] = useState(false);

  const [propertySubCategory, setPropertySubCategory] = useState([]);
  const [getLevelsInASubCommunity, setGetLevelsInASubCommunity] = useState([]);
  const {
    property_for,
    property_city,
    property_community,
    property_type,
    property_subcomm,
  } = useParams();
 
  const orignalSubCommunity = property_subcomm?.split("-").join(" ");
  const orignalCommunity = property_community?.split("-").join(" ");
  const { loading: communityloading, community } = useSelector(
    (state) => state.community
  );
  const [propertyCategory, setpropertyCategory] = useState([]);

  const [getSubCommunitiesInaCommunity, setGetSubCommunitiesInaCommunity] =
    useState([]);
  const dispatch = useDispatch();
  let history = useHistory();
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getSubCommunitiesPropertiesUtils(
          "dubai",
          property_type,
          property_for,
          orignalSubCommunity,
          orignalCommunity
        );
        setGetLevelsInASubCommunity(data);
        setLoading2(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getExclusivePropertiesInaSubCommunityUtils(
          property_for,
          orignalSubCommunity
        );
        setPropertySubCategory(data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (communityloading || loading) {
    return <Loader />;
  }
  return (
    <>
      <NavbarV2 />
      <MetaData title={` ${capitalizeWords("Properties")} For ${capitalizeWords(property_for)} In ${replaceHyphensAndCapitalize(orignalSubCommunity)}`+`  ` +`-`+` `+` `+ `Ya Homes Real Estate`} metaDesription={`Check Our Verified Listing Of Dubai ${capitalizeWords("Properties")} For ${capitalizeWords(property_for)} In ${capitalizeWords(orignalSubCommunity)} With World Class Amenities, Amazing Views And Attractive Lifestyle.`}/>

       <SubCommunityHeader 
        city={"Dubai"}
        type={"Properties"}
        for={capitalizeWords(property_for)}
        comm={capitalizeWords(orignalCommunity)}
        subComm={orignalSubCommunity}
      /> 


       {/* Conditionally render LevelsInASubCommunity if getLevelsInASubCommunity is not empty */}
       {getLevelsInASubCommunity.length > 0 && (
                <LevelsInASubCommunity subComm={orignalSubCommunity} getLevelsInASubCommunity={getLevelsInASubCommunity} />
            )}
            {propertySubCategory.length > 0 && (
  <PropertyGrid related={true} categoryProperties={propertySubCategory} />
)}
    </>
  );
};

export default ExclusivePropertiesSubCommunity;
