import React, { useEffect } from 'react';
import Navbar from './global-components/navbar-v2';
import PageHeader from './global-components/page-header';
import ContactForm from './section-components/contact-form';
import Footer from './global-components/footer-v2';
import TeamMemberDetails from './section-components/TeamMemberDetails';
import { useDispatch, useSelector } from 'react-redux';
import { getAgentProperty, getTeamDetailsApi } from '../actions/teamActions';
import { useParams, useHistory } from 'react-router-dom';
import Loader from '../layout/Loader/Loader';
import PropertyGrid from './PropertyGrid';

const TeamMember = () => {
    const { name, id } = useParams();

    const { loading, teamDetails } = useSelector((state) => state.teamDetails);

const dispatch=useDispatch()

  useEffect(() => {
    

    dispatch(getTeamDetailsApi(id));
    dispatch(getAgentProperty(id))

  }, [dispatch]);

  if(loading){
    return <Loader/>
  }
    return <div>
        <Navbar />
        <TeamMemberDetails details={teamDetails} />
        {/* <PropertyGrid id={}/> */}
        <Footer />
    </div>
}

export default TeamMember

