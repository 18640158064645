import React, { useEffect, useState } from "react";
import NavbarV2 from "../navbar-v2";
import MetaData from "../../../layout/MetaData";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  capitalizeWords,
  getPropertiesCategoryUtils,
  getSubCommunitiesInaCommunityUtils,
} from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import CommunityHeader from "./Community/CommunityHeader";
import SubCommunitiesInACommunity from "./Community/SubCommunitiesInACommunity";
import PropertyGrid from "../../PropertyGrid";

const CommunityPage = () => {
  const { property_city, property_type, property_for, property_community } =
    useParams();
  const orignalCommunity = property_community?.split("-").join(" ");
  const { loading: ld, community } = useSelector((state) => state.community);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [propertyCategory, setpropertyCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [getSubCommunitiesInaCommunity, setGetSubCommunitiesInaCommunity] =
    useState([]);

  const dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getPropertiesCategoryUtils(
          property_city,
          property_type,
          property_for,
          orignalCommunity
        );
        setpropertyCategory(data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading2(true);
        const data = await getSubCommunitiesInaCommunityUtils(
          property_type,
          property_for,
          orignalCommunity
        );
        setGetSubCommunitiesInaCommunity(data);
        setLoading2(false);
      } catch (error) {
        console.error(error);
        setLoading2(false);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <NavbarV2 />
      <MetaData
        title={
          ` ${capitalizeWords(property_type)} For ${capitalizeWords(
            property_for
          )} In ${capitalizeWords(orignalCommunity)}` +
          `  ` +
          `-` +
          ` ` +
          ` ` +
          `Ya Homes Real Estate`
        }
        metaDesription={`Check Our Verified Listing Of Dubai ${capitalizeWords(
          property_type
        )} For ${capitalizeWords(property_for)} In ${capitalizeWords(
          orignalCommunity
        )} With World Class Amenities, Amazing Views And Attractive Lifestyle`}
      />

      <CommunityHeader city={capitalizeWords(property_city)} type={capitalizeWords(property_type)} for={capitalizeWords(property_for)} comm={capitalizeWords(orignalCommunity)} />
   
      <SubCommunitiesInACommunity community={capitalizeWords(orignalCommunity)} loading={loading2} getSubCommunitiesInaCommunity={getSubCommunitiesInaCommunity}/>
      <PropertyGrid related={true} categoryProperties={propertyCategory}/>

    </>
  );
};

export default CommunityPage;
