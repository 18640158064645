import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export const TravelTimeSection = () => {
    const features = [
      { icon: "fa-building", title: "30 minutes" ,sub:"to Downtown Dubai"},
      { icon: "fa-plane",title: "30 minutes", sub: "to DXB Airport" },

      { icon: "fa-ship", title: "10 minutes",sub:"to Dubai Marina"  },
      { icon: "fa-plane",title: "10 minutes", sub: "to DWC Airport" },
    ];
  
    return (
        <div className="yellowish-background">
      <Container className="mt-100">
        <Row>
          {features.map((feature, index) => (
            <Col xs={12} sm={6} md={3} key={index} className="mb-4">
              <div className="text-center feature-item">
                <i className={`fas ${feature.icon} fa-3x mb-3 icon`} />
                <h4>{feature.title}</h4>
                <h6>{feature.sub}</h6>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
    )
  };