import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { replaceSpacesWithHyphensAndLowerCase } from '../../../../utils';
import PropertyGrid from '../../../PropertyGrid';

const SubCommunitiesInACommunity = ({ community, loading, getSubCommunitiesInaCommunity }) => {
  const { property_city, property_type, property_for, property_community } = useParams();

  // State to track the number of sub-communities to display
  const [displayCount, setDisplayCount] = useState(20);

  // Function to increase the display count
  const showMore = () => {
    setDisplayCount(displayCount + 20);
  };

  // Function to decrease the display count and show "Read Less"
  const showLess = () => {
    setDisplayCount(20);
  };

  return (
    <div className="sub-communities-wrapper">
      <section className="sub-communities-section">
        <Container>
          <h2>Sub-Communities in {community}</h2>
          <Row>
            {getSubCommunitiesInaCommunity.slice(0, displayCount).map((subCommunity, index) => (
              <Col md={3} key={index}>
                <div className="sub-community-item">
                  <Link to={`/${property_city}/${property_community}/${property_type}-for-${property_for}-${replaceSpacesWithHyphensAndLowerCase(subCommunity.sub_community)}`}>
                    {subCommunity.sub_community}
                  </Link>
                </div>
              </Col>
            ))}
          </Row>

          {/* Show "Read More" or "Read Less" button based on the display count */}
          {getSubCommunitiesInaCommunity.length > displayCount ? (
            <div className="text-center">
              <Button onClick={showMore} variant="link">
                Read More
              </Button>
            </div>
          ) : (
            <div className="text-center">
              <Button onClick={showLess} variant="link">
                Read Less
              </Button>
            </div>
          )}
        </Container>
      </section>
    </div>
  );
};

export default SubCommunitiesInACommunity;
